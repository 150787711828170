import { ActivityListModel, BaseModel, COMPANY_TYPES, GENDER, UploadModel } from './Base.Models';
import { LocationModel } from './Location.Models';
import { RoleHasPermission, PERMISSION, RoleModel, ROLE, MODULE } from './Role.Models';
import { InvoiceModel } from './Stripe.Models';
import { ServiceModel } from './Service.Models';
import { SubscriptionModel } from './Subscription.Models';

export class CompanyModel extends BaseModel {
	public constructor(params = {}) {
		super();
		Object.assign(this, params);
	}
	public id?: number;
	public name?: string;
	public type?: COMPANY_TYPES;
}
export class UserModel extends BaseModel {
	public constructor(params = {}) {
		super();
		Object.assign(this, params);
	}
	public id?: number;
	public abn?: string;
	public firstName?: string;
	public lastName?: string;
	public phone?: string;
	public role?: string | number;
	public email?: string;
	public owner?: string;
	public addedBy?: string;
	public password?: string;
	public image?: string;
	public confirmPassword?: string;
	public gender?: GENDER;
	public isBlocked?: boolean;
	public upload?: UploadModel;
	public uploads?: Array<UploadModel>;
	public licenses?: Array<UploadModel>;
	public insurences?: Array<UploadModel>;
	public companyName?: string;
	public company?: CompanyModel;
	public roles?: Array<RoleModel>;
	public subscriptions?: Array<SubscriptionModel>;
	public invoices?: Array<InvoiceModel>;
	public locations?: Array<LocationModel | number | string> | null;
	public status?: 'ACTIVE' | 'INACTIVE' | 'BLOCKED' | 'DRAFT' | 'INVITED';
	public activity?: Array<ActivityListModel> = [];
	public numberOfLogins?:number;
}

export class EmployeeModel extends UserModel {
	public constructor(params = {}) {
		super();
		Object.assign(this, params);
	}
	public id?: number;
	public firstName?: string;
	public lastName?: string;
	public name?: string;
	public phone?: string;
	public abn?: string;
	public role?: string | number;
	public email?: string;
	public owner?: string;
	public addedBy?: string;
	public password?: string;
	public image?: string;
	public confirmPassword?: string;
	public gender?: GENDER;
	public service?: Array<ServiceModel>;
	public isBlocked?: boolean;
	public contactName?: string;
	public address?: string;
	public status?: 'ACTIVE' | 'INACTIVE' | 'BLOCKED' | 'DRAFT';
	public upload?: UploadModel;
	public licenseExp?: Date | string;
	public insuarenceExp?: Date | string;
	public licenses?: Array<UploadModel>;
	public insurences?: Array<UploadModel>;
}

export class ContractortModel extends UserModel {
	public constructor(params = {}) {
		super();
		Object.assign(this, params);
	}
	public id?: number;
	public companyName!: string;
	public service!: Array<ServiceModel>;
	public licenseReq?: boolean;
	public insuarenceReq?: boolean;
	public contactName!: string;
	public locations?: Array<LocationModel>;
	public invite!: string;
	public contractorNotes?: NotesModel;
}

export class NotesModel {
	notes?: string;
}

export class AuthUserModel extends UserModel {
	public constructor(params = {}) {
		super(params);
		Object.assign(this, params);
	}

	public permissions!: Array<RoleHasPermission>;

	public can(module: Array<MODULE> | MODULE, permission: PERMISSION = PERMISSION.VIEW): boolean {
		let check: boolean = false;
		for (const item of this.permissions || []) {
			if (Array.isArray(module) && module.includes(item?.module?.slug!) && item.permission?.slug === permission) {
				return (check = true);
			}
			if (!Array.isArray(module) && module === item.module?.slug && item.permission?.slug === permission) {
				return (check = true);
			}
		}
		return check;
	}

	public hasRole(role: ROLE): boolean {
		return (this.roles || []).findIndex((item: RoleModel) => item.slug === role) > -1;
	}
}

export class LoadUserModel {
	public token!: string;
	public user!: UserModel;
}