/* eslint-disable import/no-webpack-loader-syntax */
// import { Theme } from '@mui/material/styles';
import { Map as Maps } from "Helpers/Array";
import { useEffect, useState } from "react";
import { MuiBox, MuiTypography, /*MuiGrid*/ } from "Components/MUI";
import { Map as GoogleMap, Marker, GoogleApiWrapper } from "google-maps-react";
import InfoWindowWrap from "./InfoWindowWrap";
import { DefaultImgAvatar } from 'Helpers/Images';
import { LocationOnOutlinedIcon, HomeOutlinedIcon, PersonOutlineOutlinedIcon, AccessTimeOutlinedIcon } from 'Helpers/Icons';
import { GetDateTime, GetImage } from "Helpers/String";

const GoogleMapComponent = (props) => {
    const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [locationDetails, setLocationDetails] = useState(null)
    const [viewport] = useState({
        initialCenter: {
            lat: -23.585393,
            lng: 133.7751,
        },
        zoom: 4,
        width: 100,
        height: 100
    });

    useEffect(() => {
        const { mapLocations } = props;
        let locationArr = [];
        if (mapLocations.length) {
            Maps(mapLocations, (location, index) => {
                locationArr.push({
                    id: location.id,
                    mapId: location.mapId,
                    latitude: location.lat,
                    longitude: location.lon,
                    address: location.address,
                    name: location.name,
                    logo: location?.logo?.path || null,
                    checkIns: location?.checkIns,
                    assignedUsers:location?.assignedUsers
                });
            })
        }
        setMarkers(locationArr);
    }, [props]);



    const onMarkerClick = (item, marker, e) => {
        setActiveMarker(marker)
        setLocationDetails(item)
        setShowInfoWindow(true)
    }

    const navigateToLocatiionPage = () => {
        if (locationDetails)
            props.history.push("/locations/" + locationDetails.id)
    }


    return (
        <MuiBox sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        }}
        >
            <GoogleMap style={{ height: "100%", width: "100%" }} google={props.google} zoom={viewport.zoom} initialCenter={viewport.initialCenter} streetViewControl={false} fullscreenControl={false}>

                {
                    markers?.map((item, index) => {
                        return <Marker key={index}
                            onClick={(props, marker, e) =>
                                onMarkerClick(item, marker, e,)
                            }
                            position={{ lat: item.latitude, lng: item.longitude }}

                        >

                        </Marker>
                    })
                }
                <InfoWindowWrap
                    marker={activeMarker}
                    visible={showInfoWindow}
                >
                    <MuiBox sx={[
                        (theme) => ({
                            padding: '25px 12px 12px',
                            [theme.breakpoints.down('sm')]: {
                                padding: '25px 0 0',
                            },
                            '& .locationTopBox': {
                                [theme.breakpoints.down('sm')]: {
                                    display: 'block',
                                },
                            },
                            '& h4': {
                                color: 'secondary.dark',
                                fontWeight: 600,
                                fontSize: 14,
                                '& span': {
                                    fontWeight: 500,
                                    color: 'info.contrastText',
                                    ml: 1,
                                },
                                '& a': {
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    ml: 1,
                                    outline: 'none !important',
                                    boxShadow: 'none !important',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    }
                                }
                            },
                            '& .detailsLftImg': {
                                margin: '0 20px 0 0',
                                [theme.breakpoints.down('sm')]: {
                                    margin: '0 0 20px 0',
                                },
                                '& img': {
                                    width: 60,
                                    height: 60,
                                    borderRadius: '50%',
                                    boxShadow: '0 0 15px #00000026',
                                }
                            },
                            '& .detailsRghtList': {
                                '& svg': {
                                    color: '#979797',
                                    marginTop: '-2px',
                                },
                                '& h3': {
                                    color: '#141414',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    margin: '0 0 4px 0',
                                    textTransform: 'uppercase',
                                },
                                '& p': {
                                    color: '#626262',
                                    fontSize: 14,
                                    cursor: 'pointer',
                                }
                            },
                            '& .bottomBox': {
                                border: '1px solid #dedede',
                                borderRadius: '8px',
                                padding: '5px 12px',
                                marginTop: '20px',
                                '& .detailsLftImg': {
                                    marginRight: '12px',
                                },
                                '& img': {
                                    width: 50,
                                    height: 50,
                                },
                                '& .bottomBoxList': {
                                    borderBottom: '1px solid #dedede',
                                    padding: '12px 0',
                                    [theme.breakpoints.down('sm')]: {
                                        display: 'block',
                                    },
                                    '&:last-child': {
                                        borderBottom: 'none'
                                    }
                                },
                                '& .detailsRghtList': {
                                    '& h3': {
                                        margin: '0 0 10px 0',
                                    }
                                }
                            },
                        })
                    ]}
                    >
                        <MuiBox display='flex' alignItems='center' className='locationTopBox'>
                            <MuiBox className='detailsLftImg'>
                                <img src={GetImage(locationDetails?.logo || '') || DefaultImgAvatar} alt="img" />
                            </MuiBox>
                            <MuiBox className="detailsRghtMain">
                                <MuiBox display='flex' mb={1.5} className='detailsRghtList'>
                                    <LocationOnOutlinedIcon sx={{ mr: 0.5 }} />
                                    <MuiBox>
                                        <MuiTypography variant='h3'>Location Name:</MuiTypography>
                                        <MuiTypography>{locationDetails?.name || ''}</MuiTypography>
                                    </MuiBox>
                                </MuiBox>

                                <MuiBox display='flex' className='detailsRghtList'>
                                    <HomeOutlinedIcon sx={{ mr: 0.5 }} />
                                    <MuiBox>
                                        <MuiTypography variant='h3'>Address:</MuiTypography>
                                        <MuiTypography onClick={navigateToLocatiionPage} id="test" to={`/locations`}>{locationDetails?.address || ''}</MuiTypography>
                                    </MuiBox>
                                </MuiBox>
                            </MuiBox>
                        </MuiBox>

                        <MuiBox className="bottomBox">
                            {
                                locationDetails?.assignedUsers?.map((user, index) => (

                                    <MuiBox key={index} display='flex' alignItems='center' className="bottomBoxList">
                                        <MuiBox className='detailsLftImg'>
                                            <img src={GetImage(user?.upload?.path || '') || DefaultImgAvatar} />
                                        </MuiBox>
                                        <MuiBox className='detailsRghtList'>
                                            <MuiTypography variant="h3" display='flex'>
                                                <PersonOutlineOutlinedIcon sx={{ mr: 0.5 }} /> {user?.firstName} {user?.lastName} 
                                            </MuiTypography>

                                            <MuiTypography display='flex'>
                                                <AccessTimeOutlinedIcon sx={{ mr: 0.5 }} /> Active - {GetDateTime(user?.lastCheckIn?.checkInAt)}
                                            </MuiTypography>
                                        </MuiBox>
                                    </MuiBox>

                                ))
                            }

                            {/* <MuiBox display='flex' alignItems='center' className="bottomBoxList">
                                <MuiBox className='detailsLftImg'>
                                    <img src={DefaultImgAvatar} />
                                </MuiBox>
                                <MuiBox className='detailsRghtList'>
                                    <MuiTypography variant="h3" display='flex'>
                                        <PersonOutlineOutlinedIcon sx={{ mr: 0.5 }} /> Elina foda
                                    </MuiTypography>

                                    <MuiTypography display='flex'>
                                        <AccessTimeOutlinedIcon sx={{ mr: 0.5 }} /> Active - 12:18 PM
                                    </MuiTypography>
                                </MuiBox>
                            </MuiBox> */}
                        </MuiBox>
                    </MuiBox>
                </InfoWindowWrap>


            </GoogleMap>
        </MuiBox>

    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
})(GoogleMapComponent);