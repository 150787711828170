import { GetDateTime } from "Helpers/String";
import { VisitHistory } from "Redux/Models/Location.Models";
import {
  BaseStateModel,
  FormModel,
  FORMTYPE,
  LocationModel,
  FIELD,
  DataTableModel,
} from "../Models";

export class FormState extends BaseStateModel {
  public loading: boolean = false;
  public formErr?: { [key: string]: string | undefined };
  public form: FormModel = {
    title: "",
    type: FORMTYPE.CHECK_IN,
    fields: [
      {
        type: FIELD.TEXT,
        value: "",
        required: false,
        title: "",
        defaultValue: "",
        number: "",
        options: [
          { value: "", selected: false },
          { value: "", selected: false },
        ],
      },
    ],
  };
}

export class LocationFormState extends BaseStateModel {
  public location: LocationModel = {};
}

export class GeneralFormState extends BaseStateModel {
  public check: LocationModel = {
    entryCondition: false,
  };

  public location: LocationModel = {
    generalName: "",
    generalAddress: "",
    generalDescription: "",
  };
}

export class NotificationFormState extends BaseStateModel {
  public location: LocationModel = {
    notificationName: "",
    notificationPhone: "",
    notificationEmail: "",
  };
}

class FormResponseModel {
  checkIn?: Array<[]>;
  checkOut?: Array<[]>;
  siteInduction?: Array<[]>;
}

export class FormResponseState extends BaseStateModel {
  public tab: number = 0;
  public inductionStatus: string = "";
  public form: FormResponseModel = {
    checkIn: [],
    checkOut: [],
    siteInduction: [],
  };
  public taskStatus: string = "";
  public dataTable: DataTableModel = {
    pagination: true,
    searchable: false,
    limit: 10,
    active: "createdAt",
    direction: "desc",
    actions: [],
    url: "/check-ins",
    headers: [
      {
        key: "CheckInAt",
        label: "Check-In At",
        isSort: true,
        render: (item: VisitHistory) => GetDateTime(item.checkInAt),
      },
      {
        key: "Check Out At",
        label: "Check-Out At",
        isSort: true,
        render: (item: VisitHistory) => GetDateTime(item.checkOutAt) || "N/A",
      },
      {
        key: "time",
        label: "Time Spend",
        isSort: true,
        // render: (item: EmployeeModel) => `${item.firstName || ''} ${item.lastName || ''}`
      },
    ],
    filters: [],
  };
}
