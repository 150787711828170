import { Component, ComponentType } from 'react';
import loadable from '@loadable/component';
import { Fallback } from 'Components';
import { AuthLayout, DashboardLayout } from 'Layouts';

/* AUTH MANAGEMENT */
const LoginView = loadable(() => import('../Views/Auth/LoginView'), { fallback: <Fallback /> });
const PlanView = loadable(() => import('../Views/Auth/PriceView'), { fallback: <Fallback /> });
const ForgotPasswordView = loadable(() => import('../Views/Auth/RequestPasswordView'), {
	fallback: <Fallback />
});
const ResetPasswordView = loadable(() => import('../Views/Auth/ResetPasswordView'), {
	fallback: <Fallback />
});
const ClientRegisterView = loadable(() => import('../Views/Auth/RegisterView'), {
	fallback: <Fallback />
});
const InviteClientPlansView = loadable(() => import('../Views/Auth/PriceView'), {
	fallback: <Fallback />
});
const InviteClientRegisterView = loadable(() => import('../Views/Auth/RegisterView'), {
	fallback: <Fallback />
});

const InviteContractorRegisterView = loadable(() => import('../Views/Auth/RegisterView'), {
	fallback: <Fallback />
});

// DASHBOARD
const HomeView = loadable(() => import('../Views/Dashboard'), { fallback: <Fallback /> });
const PaymentSuccessView = loadable(() => import('../Views/Dashboard/PaymentSuccessView'), {
	fallback: <Fallback />
});
const PaymentFailedView = loadable(() => import('../Views/Dashboard/PaymentFailedView'), {
	fallback: <Fallback />
});
const FormHistoryView = loadable(() => import('../Views/Dashboard/FormHistoryView'), { fallback: <Fallback /> });

/* USER MANAGEMENT  */
const UserListView = loadable(() => import('../Views/Users/UserListView'), {
	fallback: <Fallback />
});
const AddUserView = loadable(() => import('../Views/Users/AddUserView'), {
	fallback: <Fallback />
});
const EditUserView = loadable(() => import('../Views/Users/AddUserView'), {
	fallback: <Fallback />
});
const UserDetailView = loadable(() => import('../Views/Users/UserDetailView'), {
	fallback: <Fallback />
});

/* ROLE MANAGEMENT */
const RoleListView = loadable(() => import('../Views/Roles/RoleListView'), {
	fallback: <Fallback />
});
const AddRoleView = loadable(() => import('../Views/Roles/AddRoleView'), {
	fallback: <Fallback />
});
const EditRoleView = loadable(() => import('../Views/Roles/AddRoleView'), {
	fallback: <Fallback />
});
const RoleDetailView = loadable(() => import('../Views/Roles/RoleDetailView'), {
	fallback: <Fallback />
});

// PROFILE
const ProfileView = loadable(() => import('../Views/Profile'), { fallback: <Fallback /> });

const MySubscriptionView = loadable(() => import('../Views/Profile/MySubscriptionView'), {
	fallback: <Fallback />
});

// CLIENT MANAGEMENT 
const ClientListView = loadable(() => import('../Views/Clients/ClientListView'), {
	fallback: <Fallback />
});

const ClientDetailView = loadable(() => import('../Views/Clients/ClientDetailView'), {
	fallback: <Fallback />
});

// SITE
const LocationListView = loadable(() => import('../Views/Locations/LocationListView'), {
	fallback: <Fallback />
});
const LocationFormView = loadable(() => import('../Views/Locations/LocationFormView'), {
	fallback: <Fallback />
});
const LocationDetailView = loadable(() => import('../Views/Locations/LocationDetailView'), {
	fallback: <Fallback />
});
const AddLocationView = loadable(() => import('../Views/Locations/AddLocationView'), {
	fallback: <Fallback />
});
const EditLocationView = loadable(() => import('../Views/Locations/AddLocationView'), {
	fallback: <Fallback />
});

// Contractor
const ContractorsListView = loadable(() => import('../Views/Contractors/ContractorsListView'), {
	fallback: <Fallback />
});
const InviteContractorView = loadable(() => import('../Views/Contractors/InviteContractorView'), {
	fallback: <Fallback />
});
const ContractorProfileView = loadable(() => import('../Views/Contractors/ContractorProfileView'), {
	fallback: <Fallback />
});

const AddServiceHistoryView = loadable(() => import('../Views/ServiceHistory/AddServiceHistoryView'), { fallback: <Fallback /> });
const ServiceHistoryListView = loadable(() => import('../Views/ServiceHistory/ServiceHistoryListView'), { fallback: <Fallback /> });

const ReportListView = loadable(() => import('../Views/Reports/ReportListView'), {
	fallback: <Fallback />
});

const ServiceListView = loadable(() => import('../Views/Services/ServiceListView'), {
	fallback: <Fallback />
});
const ServiceAddView = loadable(() => import('../Views/Services/ServiceAddView'), {
	fallback: <Fallback />
});
const ServiceDetailView = loadable(() => import('../Views/Services/ServiceDetailView'), {
	fallback: <Fallback />
});

const BroadcastListView = loadable(() => import('../Views/Broadcast/BroadcastListView'), {
	fallback: <Fallback />
});

const BroadCastAddView = loadable(() => import('../Views/Broadcast/BroadCastAddView'), {
	fallback: <Fallback />
});
const NotificationListView = loadable(() => import('../Views/NotificationListView'), {
	fallback: <Fallback />
});

// Visitor/Contractor company admin
// const VisitorProfileView = loadable(() => import('../Views/VisitorCompanyAdmin/VisitorProfileView'), { fallback: <Fallback /> });
// const ActivityLogView = loadable(() => import('../Views/ActivityLog/ActivityLogView'), {
// 	fallback: <Fallback />
// });
const EmployeeListView = loadable(() => import('../Views/Employees/EmployeeListView'), {
	fallback: <Fallback />
});
const EmployeeDetailsView = loadable(() => import('../Views/Employees/EmployeeDetailsView'), {
	fallback: <Fallback />
});
const InviteEmployeeView = loadable(() => import('../Views/Employees/InviteEmployeeView'), {
	fallback: <Fallback />
});
const EditEmployeeView = loadable(() => import('../Views/Employees/InviteEmployeeView'), {
	fallback: <Fallback />
});
const UpgradeSubscriptionView = loadable(() => import('../Views/Subscriptions/UpgradeSubscriptionView'), {
	fallback: <Fallback />
});
const RePaymentSubscriptionView = loadable(() => import('../Views/Subscriptions/UpgradeSubscriptionView'), {
	fallback: <Fallback />
});
const SubscriptionEditListView = loadable(() => import('../Views/Subscriptions/SubscriptionEditListView'), {
	fallback: <Fallback />
});
const SubscriptionEditView = loadable(() => import('../Views/Subscriptions/SubscriptionEditView'), {
	fallback: <Fallback />
});

export interface RouteInterface {
	auth?: boolean;
	path: string;
	exact?: boolean;
	name?: string;
	component: ComponentType<any>;
	childrens?: Array<RouteInterface>;
	redirect?: string;
}

export const routes: Array<RouteInterface> = [
	{ path: '/prices', exact: true, name: 'Pre-Dashboard', component: PlanView },
	{
		path: '/prices/:token',
		exact: true,
		name: 'Client Invite Plans',
		component: InviteClientPlansView
	},
	{
		path: '',
		auth: false,
		exact: true,
		name: 'Login',
		component: AuthLayout,
		childrens: [
			{
				path: '/reset-password/:token',
				exact: true,
				name: 'Reset Password',
				component: ResetPasswordView
			},
			{ path: '/login', exact: true, name: 'Login', component: LoginView },
			{
				path: '/forgot-password',
				exact: true,
				name: 'Forgot Password',
				component: ForgotPasswordView
			},
			{
				path: '/register',
				exact: true,
				name: 'Client Management Signup',
				component: ClientRegisterView
			},
			{
				path: '/register/plan/:id/:token',
				exact: true,
				name: 'Client Management Signup',
				component: InviteClientRegisterView
			},
			{
				path: '/register/:token',
				exact: true,
				name: 'Client Management Signup',
				component: InviteContractorRegisterView
			},
			{ path: '/verify-user/:token', exact: true, name: 'Verification', component: LoginView },
			{ path: '/successed', exact: true, name: '', component: PaymentSuccessView },
			{ path: '', redirect: '/login', component: Component }
		]
	},
	{
		path: '',
		exact: true,
		auth: true,
		name: 'Dashboard',
		component: DashboardLayout,
		childrens: [
			{ path: '/plans', exact: true, name: 'Plans', component: RePaymentSubscriptionView },
			{ path: '/dashboard', exact: true, name: 'Dashboard', component: HomeView },
			{ path: '/successed', exact: true, name: '', component: PaymentSuccessView },
			{ path: '/failed', exact: true, name: '', component: PaymentFailedView },
			{ path: '/form-history/:locationId/:userId', exact: true, name: 'Form History', component: FormHistoryView },

			//USER MANAGEMENT
			{ path: '/users', exact: true, name: 'Users', component: UserListView },
			{ path: '/users/new', exact: true, name: 'Add User', component: AddUserView },
			{ path: '/users/edit/:id', exact: true, name: 'Edit User', component: EditUserView },
			{ path: '/users/:id', exact: true, name: 'User View', component: UserDetailView },

			//ROLE MANAGEMENT
			{ path: '/roles', exact: true, name: 'Roles', component: RoleListView },
			{ path: '/roles/new', exact: true, name: 'Add Role', component: AddRoleView },
			{ path: '/roles/edit/:id', exact: true, name: 'Edit Role', component: EditRoleView },
			{ path: '/roles/:id', exact: true, name: 'Role Details', component: RoleDetailView },

			//CLIENT MANAGEMENT
			{ path: '/clients', exact: true, name: 'Client Organization', component: ClientListView },
			{ path: '/clients/:id', exact: true, name: 'Client Profile', component: ClientDetailView },

			//SERVICE MANAGEMENT
			{ path: '/services', exact: true, name: 'Services', component: ServiceListView },
			{ path: '/services/new', exact: true, name: 'Add Service', component: ServiceAddView },
			{ path: '/services/edit/:id', exact: true, name: 'Edit Service', component: ServiceAddView },
			{ path: '/services/:id', exact: true, name: 'Service Details', component: ServiceDetailView },

			// LOCATION MANAGEMENT
			{ path: '/locations', exact: true, name: 'Locations', component: LocationListView },
			{ path: '/locations/new', exact: true, name: 'Add Location', component: AddLocationView },
			{
				path: '/locations/edit/:id',
				exact: true,
				name: 'Edit Location',
				component: EditLocationView
			},
			{
				path: '/locations/:id',
				exact: true,
				name: 'Location Details',
				component: LocationDetailView
			},
			{ path: '/locations/:id/forms', exact: true, name: 'Edit Form', component: LocationFormView },

			//Service History
			{ path: '/service-history', exact: true, name: 'Service History', component: ServiceHistoryListView },
			{ path: '/service-history/add', exact: true, name: 'Assign Service', component: AddServiceHistoryView },

			//SETTTINGS
			{ path: '/broadcasts', exact: true, name: 'Broadcast', component: BroadcastListView },
			{ path: '/broadcasts/new', exact: true, name: 'Broadcast', component: BroadCastAddView },
			{
				path: '/notifications',
				exact: true,
				name: 'Notifications',
				component: NotificationListView
			},

			{ path: '/profile', exact: true, name: 'My Profile', component: ProfileView },
			{
				path: '/contractors',
				exact: true,
				name: 'Contractor Organization',
				component: ContractorsListView
			},
			{
				path: '/contractors/invite',
				exact: true,
				name: 'Invite Contractor Organization',
				component: InviteContractorView
			},
			{
				path: '/contractors/edit/:id',
				exact: true,
				name: 'Edit Contractor Organization',
				component: InviteContractorView
			},
			{
				path: '/contractors/:id',
				exact: true,
				name: 'Contractor Organization Profile',
				component: ContractorProfileView
			},
			{ path: '/reports', exact: true, name: 'Reports', component: ReportListView },

			// VISITOR/CONTRACTOR
			// {
			// 	path: '/visitor/profile',
			// 	exact: true,
			// 	name: 'Contractor Organization',
			// 	component: VisitorProfileView
			// },
			{ path: '/employees', exact: true, name: 'Employees', component: EmployeeListView },
			{
				path: '/employees/invite',
				exact: true,
				name: 'Invite Employee',
				component: InviteEmployeeView
			},
			{
				path: '/employees/:id',
				exact: true,
				name: 'Employee Details',
				component: EmployeeDetailsView
			},
			{
				path: '/employees/edit/:id',
				exact: true,
				name: 'Employee Edit',
				component: EditEmployeeView
			},

			// SUBSCRIPTION
			{ path: '/subscription', exact: true, name: 'My Subscription', component: MySubscriptionView },
			{ path: '/upgrade', exact: true, name: 'Upgrade Subscription', component: UpgradeSubscriptionView },
			{ path: '/subscriptions', exact: true, name: 'Subscriptions', component: SubscriptionEditListView },
			{ path: '/subscription-update/:id', exact: true, name: 'Update Subscription', component: SubscriptionEditView },
			{ path: '', redirect: '/dashboard', component: Component }
		]
	}
];
