import { MuiBox, MuiIconButton, MuiTypography, } from 'Components/MUI';
import { Component, createRef, RefObject } from 'react';
import { DialogTitle, DialogContent, DialogProps } from '@mui/material';
import { StyleDialog } from './styled';
import { Props, ServiceHistoryModel, ServiceHistoryVisitModel } from 'Redux/Models';
import { UploadedFilesDialogState, CheckInCheckOutListState } from 'Redux/States';
import { CloseIcon } from 'Helpers/Icons';
import { GetDateTime } from 'Helpers/String';
import { Map } from 'Helpers/Array';
import { Theme } from '@mui/material/styles';
import { DataTable } from 'Components';
import { VisitHistory } from 'Redux/Models/Location.Models';

interface ModalPropsType extends DialogProps {
    // data?: ServiceHistoryVisitModel[] | null,
    data?: { userId?: Number, locationId?: Number },
}

export class CheckinCheckoutDialog extends Component<ModalPropsType, any> {
    public state: CheckInCheckOutListState = new CheckInCheckOutListState();

    // public dataTable: RefObject<DataTable<ServiceHistoryModel>>;
    public dataTable: RefObject<DataTable<VisitHistory>>;

    constructor(props: ModalPropsType) {
        super(props);
        this.dataTable = createRef();
        if (props.data?.locationId && props.data?.userId) {
            this.state.dataTable.url += `&userId=${props.data?.userId}&locationId=${props.data?.locationId}`
        }
    }

    public handleChange = (event: React.SyntheticEvent, newValue: '1' | '2') => {
        this.setState((prevState: UploadedFilesDialogState) => {
            prevState.tab = newValue
            return prevState
        })
    }

    render() {
        const { onClose, data, ...restProps } = this.props
        const { dataTable } = this.state
        return (
            <StyleDialog {...restProps} onClose={onClose} className="bigModalMainWrap checkInOutModalMainWrap">
                <DialogTitle >
                    Check-In / Check-Out
                    {onClose &&
                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                            <CloseIcon />
                        </MuiIconButton>
                    }
                </DialogTitle>
                <DialogContent>
                    <MuiBox>
                        <MuiBox
                            sx={[
                                (theme: Theme) => ({
                                    minWidth: 650,
                                    [theme.breakpoints.down('md')]: {
                                        minWidth: '100%',
                                    },
                                })
                            ]}
                        >
                            <MuiBox>
                                <DataTable<VisitHistory>
                                    ref={this.dataTable}
                                    {...dataTable}
                                    // onActionClick={null}
                                    onAddClick={undefined}
                                />
                            </MuiBox>
                            {/* {data && data.length > 0 ? Map(data, (check: ServiceHistoryVisitModel, checkIndex: number) => (
                                <MuiBox key={checkIndex} sx={{
                                    backgroundColor: '#fbfbfb',
                                    padding: '10px 15px',
                                    borderRadius: '5px',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    border: '1px solid #e7e7e7',
                                    '& a': {
                                        fontSize: 16,
                                        fontWeight: 600,
                                        display: 'block',
                                        color: 'primary.main',
                                        width: 'fit-content',
                                        '&:hover': {
                                            color: 'primary.dark',
                                        }
                                    },
                                    '& p': {
                                        fontSize: 16,
                                        mt: 1,
                                        mb: 0,
                                    },
                                    '& .checkInOutDetailsP': {
                                        fontSize: 15,
                                        margin: 0,
                                        width: 200,
                                        '& span': {
                                            color: 'secondary.dark',
                                            display: 'block',
                                            fontWeight: 600,
                                        }
                                    }
                                }}>
                                    <MuiTypography className='checkInOutDetailsP'>
                                        <MuiBox component="span">Check-In Time:</MuiBox>
                                        {GetDateTime(check.checkInAt as any) || 'N/A'}
                                    </MuiTypography>

                                    <MuiTypography className='checkInOutDetailsP'>
                                        <MuiBox component="span">Check-Out Time:</MuiBox>
                                        {GetDateTime(check.checkOutAt as any) || 'N/A'}
                                    </MuiTypography>

                                    <MuiTypography className='checkInOutDetailsP'>
                                        <MuiBox component="span">Time onsite:</MuiBox>
                                        {check?.time || 'N/A'}
                                    </MuiTypography>
                                </MuiBox>
                            )) : <MuiTypography sx={{ textAlign: 'center', padding: '60px 20px', fontSize: 16, }}>No Check-In / Check-Out Found</MuiTypography>} */}


                        </MuiBox>
                    </MuiBox>
                </DialogContent>
            </StyleDialog>
        )
    }
}

export default CheckinCheckoutDialog;