import { MuiAvatar, MuiBox, MuiButton, MuiCheckbox, MuiDatePicker, MuiGrid, MuiIconButton, MuiListItem, MuiListItemAvatar, MuiListItemText, MuiPaper, MuiTypography, MuiUploadButton } from 'Components/MUI'
import { Theme } from '@mui/material/styles';
import { Component, Fragment } from 'react'
import ErrorMsg from 'Components/ErrorMsg';
import { Map } from 'Helpers/Array';
import { Props, ROLE, UploadModel, UserModel } from 'Redux/Models';
import { CloseIcon, InsertDriveFileIcon } from 'Helpers/Icons';
import { DocumentVerificationState } from 'Redux/States';
import { Alert } from 'Helpers/Alert';
import { LoadUser } from 'Redux/Actions';
import { AuthService, ContractorService, EmployeeService } from 'Services';
import { GetDate } from 'Helpers/String';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import PendingIcon from '@mui/icons-material/Pending';

enum STATUS {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
    EXPIRED = "EXPIRED"
}

const COLORS = {
    APPROVED: '#4CAF50',
    REJECTED: '#f2331e',
    PENDING: '#f0d200',
    EXPIRED: "#f2331e"
}

export default class DocumentVerification extends Component<Props, DocumentVerificationState> {
    public state: DocumentVerificationState = new DocumentVerificationState();
    constructor(props: Props) {
        super(props);
        this.state.user = props.user;
    }

    public componentDidUpdate(prevProps: Readonly<Props<unknown>>, prevState: Readonly<DocumentVerificationState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
            this.setState((prevState: DocumentVerificationState) => {
                prevState.user = this.props.user;
                return prevState;
            })
        }
    }

    public handleDateChange = (newDate: Date, type: string, data: UploadModel) => {
        this.setState((prevState: DocumentVerificationState) => {
            (prevState as any).user[type] = (prevState as any).user[type].map((doc: UploadModel) => {
                if (doc.id === data.id) doc.expDate = newDate
                return doc;
            })
            return prevState;
        })
    }

    public handleDocDelete = (type: string, file: UploadModel) => {
        Alert.confirm('Are you sure?', 'You want to delete this file').then(resp => {
            if (resp) {
                if (file.id && file.id >= 1) {
                    EmployeeService.deleteDoc(file.id).then(resp => {
                        if (resp.isSuccess()) {
                            this.setState((prevState: DocumentVerificationState) => {
                                prevState.user[type] = prevState.user[type]?.filter((f: UploadModel) => f.id !== file.id)
                                if (prevState.formErr?.[type]) delete prevState.formErr?.[type]
                                return prevState
                            }, () => LoadUser(this.state.user))
                        }
                    })
                } else {
                    this.setState((prevState: DocumentVerificationState) => {
                        prevState.user[type] = prevState.user[type]?.filter((f: UploadModel) => f.id !== file.id)
                        return prevState
                    })
                }
            }
        })
    }

    public handleDocUpload = (type: string) => {
        const element = document.createElement('input');
        element.type = 'file';
        element.name = type;
        element.addEventListener('change', (event: any) => {
            const { files } = event.target

            this.setState((prevState: DocumentVerificationState) => {
                if (prevState.formErr?.[type]) delete prevState.formErr?.[type]
                return prevState
            })

            const allowedTypes = ['jpg', 'png', 'pdf', 'jpeg']
            for (let i = 0; i < files.length; i++) {
                const item = files[i];
                const extention = item.name.split('.').pop()
                if (!allowedTypes.some(a => a === extention)) {
                    this.setState((prevState: DocumentVerificationState) => ({ ...prevState, formErr: { [type]: `allowed file types ${allowedTypes}` } }))
                    return
                }
                if (item.size > 10 ** 6 * 2) { //2MB
                    this.setState((prevState: DocumentVerificationState) => ({ ...prevState, formErr: { [type]: `maximum file size 2MB` } }))
                    return
                }
            }

            this.setState((prevState: DocumentVerificationState) => {
                for (let i = 0; i < files.length; i++) {
                    const item = files[i];
                    (prevState as any).user[type]?.push({
                        file: item,
                        name: item.name,
                        size: item.size,
                        type: item.type,
                        id: Math.random(),
                        expDate: new Date(),
                        status: STATUS.PENDING
                    })
                }
                return prevState
            })

        })
        element.click();
    }
    public handleTermsAgree = () => {
        const currentVal = this.state.termsAgree
        this.setState((prevState: DocumentVerificationState) => {
            prevState.termsAgree = !currentVal
            if (prevState.formErr?.['termsAgree']) delete prevState.formErr?.['termsAgree']
            return prevState
        })
    }

    public handleSubmitDoc = () => {
        const { user, termsAgree } = this.state;
        const { licenses, insurences } = user
        const formData = new FormData();
        let uploadedLicenses: any = []
        let uploadedInsuarances: any = []

        if (termsAgree === false && (licenses?.length || insurences?.length)) {
            const type = "termsAgree"
            this.setState((prevState: DocumentVerificationState) => {
                return { ...prevState, formErr: { [type]: "Please accept terms & condition before submit" } }
            })
            return
        }

        licenses?.forEach((doc) => {
            if (doc.id && doc.id < 1) {
                uploadedLicenses.push({ file: doc.file, expDate: GetDate(doc.expDate) })
            }
        })

        insurences?.forEach((doc) => {
            if (doc.id && doc.id < 1) {
                uploadedInsuarances.push({ file: doc.file, expDate: GetDate(doc.expDate) })
            }
        })

        uploadedLicenses?.forEach((item: any, index: number) => {
            formData.append(`licenses[${index}].license`, item.file);
            formData.append(`licenses[${index}].expDate`, GetDate(item.expDate));
        })

        uploadedInsuarances?.forEach((item: any, index: number) => {
            formData.append(`insurences[${index}].insurence`, item.file);
            formData.append(`insurences[${index}].expDate`, GetDate(item.expDate));
        })

        formData.append('type', 'docsUpload')

        if (user.hasRole(ROLE.CONTRACTOR)) {
            ContractorService.update(user.id!, formData).then(resp => {
                const data = resp.getData()
                const { licenses, insurences } = data
                this.setState((prevState: DocumentVerificationState) => {
                    prevState.user.licenses = licenses
                    prevState.user.insurences = insurences
                    prevState.termsAgree = false
                    this.props.dispatch(LoadUser(prevState.user))
                    return prevState;
                }, () => LoadUser(this.state.user))
            })
        } else {
            EmployeeService.updateEmployee(user.id!, formData).then(resp => {
                const data = resp.getData()
                const { licenses, insurences } = data
                this.setState((prevState: DocumentVerificationState) => {
                    prevState.user.licenses = licenses
                    prevState.user.insurences = insurences
                    prevState.termsAgree = false
                    return prevState;
                }, () => LoadUser(this.state.user))
            })
        }

    }

    public skipDocumentUpload = () => {
        this.props.dispatch(AuthService.increaseLoginCnt()).then((resp: any) => {

        })
    }

    public getIconStatus = (file: UploadModel) => {
        if (file) {
            switch (file.status) {
                case STATUS.APPROVED:
                    return (<><CheckCircleIcon /> Verified</>)
                case STATUS.PENDING:
                    return (<><PendingIcon /> Pending</>)
                case STATUS.REJECTED:
                    return (<><UnpublishedIcon /> Rejected</>)
                case STATUS.EXPIRED:
                    return (<><HourglassDisabledIcon /> Expired</>)
                default:
                    break
            }
        }
    }

    render() {
        const { user: { licenses, insurences }, formErr } = this.state;
        return (
            <MuiBox
                sx={[
                    (theme: Theme) => ({
                        backgroundColor: 'primary.contrastText',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        padding: '30px 20px',
                        boxShadow: '0 0 15px #0000001a',
                        margin: '15px',
                        [theme.breakpoints.down("sm")]: {
                            padding: '20px',
                        },
                        '& img': {
                            maxHeight: '350px',
                            maxWidth: '100%',
                            margin: '0 0 15px 0',
                            [theme.breakpoints.down("sm")]: {
                                maxHeight: '250px',
                            },
                        }
                    })
                ]}
            >
                <MuiTypography variant='h4' sx={{
                    color: 'secondary.dark',
                    mb: 5,
                }}>
                    Please upload your documents
                </MuiTypography>
                <MuiGrid container spacing={2}>
                    <MuiGrid item md={6} xs={12}>
                        <MuiPaper
                            variant="outlined"
                            sx={{
                                p: 3,
                                border: '2px solid',
                                borderColor: 'primary.main',
                                color: 'info.contrastText'
                            }}
                        >
                            <MuiGrid container spacing={2}>
                                <MuiGrid item sm={12} xs={12}>
                                    <MuiTypography variant="h5" color="secondary.dark">
                                        Licenses
                                    </MuiTypography>
                                </MuiGrid>
                                <MuiGrid item sm={12} xs={12}>
                                    <MuiUploadButton onClick={() => this.handleDocUpload('licenses')} variant="contained" fullWidth={true} >
                                        {licenses && licenses.length > 0 ? 'Add More' : 'Upload File'}
                                    </MuiUploadButton>
                                    <ErrorMsg msg={formErr?.licenses} />
                                </MuiGrid>
                                {licenses && Map(licenses, (license: UploadModel) => (
                                    <Fragment key={license.id}>
                                        <MuiGrid item sm={12} xs={12} sx={{ '& .MuiFormControl-root': { margin: 0 } }} >
                                            <MuiListItem sx={{ mb: 2, p: 0 }} alignItems="flex-start"
                                                secondaryAction={license.status !== STATUS.EXPIRED &&
                                                    <MuiIconButton onClick={
                                                        () => this.handleDocDelete('licenses', license)
                                                    } edge="end" aria-label="delete">
                                                        <CloseIcon />
                                                    </MuiIconButton>

                                                }
                                            >
                                                <MuiListItemAvatar>
                                                    <MuiAvatar sx={{ backgroundColor: 'primary.main' }} > <InsertDriveFileIcon /> </MuiAvatar>
                                                </MuiListItemAvatar>
                                                <MuiListItemText
                                                    primary={
                                                        <MuiTypography
                                                            variant="h6"
                                                            sx={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                WebkitLineClamp: '2',
                                                                paddingRight: '25px',
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                            <MuiBox component='span' sx={{
                                                                display: 'flex',
                                                            }}>
                                                                {license.originalName || license.name}
                                                                <MuiBox component='span' sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginLeft: '10px',
                                                                    fontWeight: 600,
                                                                    color: '#000',
                                                                    '& svg': {
                                                                        fontSize: 22,
                                                                        marginRight: '5px',
                                                                        color: license?.status && (COLORS as any)[license.status],
                                                                    }
                                                                }}>
                                                                    <>{this.getIconStatus(license)}</>
                                                                </MuiBox>
                                                            </MuiBox>
                                                        </MuiTypography>
                                                    }
                                                    secondary={
                                                        <MuiTypography variant="caption" sx={{ lineHeight: 1 }}>
                                                            {license?.size < 1000000 ? Math.round(license?.size / 1000) + ' KB' : Math.floor(license?.size / 1000000) + ' MB' || ''}
                                                        </MuiTypography>
                                                    }
                                                />
                                            </MuiListItem>
                                            <MuiDatePicker
                                                onKeyDown={(e: any) => e.preventDefault()}
                                                name="expDate"
                                                label="Expiry Date"
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(value: Date) => this.handleDateChange(value, 'licenses', license)}
                                                value={license.expDate ? new Date(license.expDate) : new Date()}
                                                defaultValue={new Date()}
                                            />
                                        </MuiGrid>

                                    </Fragment>
                                ))}

                            </MuiGrid>
                        </MuiPaper>
                    </MuiGrid>
                    <MuiGrid item md={6} xs={12}>
                        <MuiPaper
                            variant="outlined"
                            sx={{
                                p: 3,
                                border: '2px solid',
                                borderColor: 'primary.main',
                                color: 'info.contrastText'
                            }}
                        >
                            <MuiGrid container spacing={2}>
                                <MuiGrid item sm={12} xs={12}>
                                    <MuiTypography variant="h5" color="secondary.dark">
                                        Insurances
                                    </MuiTypography>
                                </MuiGrid>
                                <MuiGrid item sm={12} xs={12}>
                                    <MuiUploadButton onClick={() => this.handleDocUpload('insurences')} variant="contained" fullWidth={true} >
                                        {insurences && insurences.length > 0 ? 'Add More' : 'Upload File'}
                                    </MuiUploadButton>
                                    <ErrorMsg msg={formErr?.insurences} />
                                </MuiGrid>

                                {insurences && Map(insurences, (insurence: UploadModel) => (
                                    <Fragment key={insurence.id}>
                                        <MuiGrid item sm={12} xs={12} sx={{ '& .MuiFormControl-root': { margin: 0 } }} >
                                            <MuiListItem sx={{ mb: 2, p: 0 }} alignItems="flex-start"
                                                secondaryAction={insurence.status !== STATUS.EXPIRED &&
                                                    <MuiIconButton edge="end" aria-label="delete"
                                                        onClick={() => this.handleDocDelete('insurences', insurence)} > <CloseIcon />
                                                    </MuiIconButton>
                                                }
                                            >
                                                <MuiListItemAvatar>
                                                    <MuiAvatar sx={{ backgroundColor: 'primary.main' }} > <InsertDriveFileIcon /> </MuiAvatar>
                                                </MuiListItemAvatar>
                                                <MuiListItemText
                                                    primary={
                                                        <MuiTypography
                                                            variant="h6"
                                                            sx={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                WebkitLineClamp: '2',
                                                                paddingRight: '25px',
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                            <MuiBox component='span' sx={{
                                                                display: 'flex',
                                                            }}>
                                                                {insurence.originalName || insurence.name}
                                                                <MuiBox component='span' sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginLeft: '10px',
                                                                    fontWeight: 600,
                                                                    color: '#000',
                                                                    '& svg': {
                                                                        fontSize: 22,
                                                                        marginRight: '5px',
                                                                        color: insurence?.status && (COLORS as any)[insurence.status],
                                                                    }
                                                                }}>
                                                                    <>{this.getIconStatus(insurence)}</>
                                                                </MuiBox>
                                                            </MuiBox>
                                                        </MuiTypography>
                                                    }
                                                    secondary={
                                                        <MuiTypography variant="caption" sx={{ lineHeight: 1 }}>
                                                            {insurence?.size < 1000000 ? Math.round(insurence?.size / 1000) + ' KB' : Math.floor(insurence?.size / 1000000) + ' MB' || ''}
                                                        </MuiTypography>
                                                    }
                                                />
                                            </MuiListItem>
                                            <MuiDatePicker
                                                onKeyDown={(e: any) => e.preventDefault()}
                                                name="expDate"
                                                label="Expiry Date"
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(value: Date) => this.handleDateChange(value, 'insurences', insurence)}
                                                value={insurence.expDate ? new Date(insurence.expDate) : new Date()}
                                                defaultValue={new Date()}
                                            />
                                        </MuiGrid>

                                    </Fragment>
                                ))}
                            </MuiGrid>
                        </MuiPaper>
                    </MuiGrid>
                    <MuiGrid item md={12} xs={12} textAlign="left" mt={2} style={{ color: "grey" }}>
                        <MuiCheckbox checked={this.state.termsAgree} onChange={() => this.handleTermsAgree()} name='termsAgree' label='I hereby certify that this documentation and the expiry date I have entered is true and correct and I am duly authorized to upload this document. I also authorize Selr to use this document for check-in purposes at the site I visit or are invited to.' />
                        <ErrorMsg msg={formErr?.termsAgree} />
                    </MuiGrid>
                    <MuiGrid item md={12} xs={12} textAlign="right" mt={2}>
                        <MuiButton
                            sx={{ marginRight: "10px" }}
                            type="submit"
                            variant="contained"
                            fullWidth={false}
                            disabled={licenses && insurences && licenses?.length < 1 && insurences?.length < 1}
                            onClick={this.handleSubmitDoc}
                        >Update</MuiButton>

                        <MuiButton
                            onClick={() => this.skipDocumentUpload()}
                        >Skip</MuiButton>
                    </MuiGrid>

                </MuiGrid>
            </MuiBox>
        )
    }
}
