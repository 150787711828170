import { SvgIconComponent } from '@mui/icons-material';
import { AuthUserModel } from './User.Models';
import { MouseEventHandler, ReactElement, ReactNode } from "react";

export class InitialAuthStateModel {
  [key: string]: any;
  public token!: string;
  public deviceToken!: string;
  public isAuthenticated!: boolean;
  public isExpired!: boolean;
  public user!: AuthUserModel;
}

export class InitialConfigStateModel {
  [key: string]: any;
  public isToggled!: boolean;
}

export abstract class BaseStateModel {
  public errors = {
    has: (e: string): boolean => {
      return false;
    },
    first: (e: string): string => {
      return '';
    },
  };
}

export class DataTableHeaderModel {
  public label!: string;
  public key!: string;
  public isSort!: boolean;
  public style?: {
    [key: string]: string;
  };
  public render?: Function;
}

type Color = 'inherit' | 'primary' | 'secondary' | 'default';
type Direction = 'asc' | 'desc';

export class DataTableActionModel {
  public icon!: any;
  public key!: ACTION;
  public color?: Color;
  public tooltip?: string;
  public match?: any;
  public disableByKey?:string;
}
export class DataTableModel {
  public url!: string;
  public newBtnTitle?: string;
  public uploadBtnTitle?: string;
  public gridColFull?: boolean;
  public searchable!: boolean;
  public actions!: Array<DataTableActionModel>;
  public headers!: Array<DataTableHeaderModel>;
  public pagination!: boolean;
  public limit!: number;
  public active!: string;
  public direction!: Direction;
  public onActionClick?: Function;
  public onAddClick?: Function;
  public onUploadClick?: Function;
  public filters?: {
    name: string;
    key: string;
    multiple: boolean;
    items: Array<{ label: string; value: string | number }>;
  }[];
  public moreBtns?: {
    label?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>
    jsx?: JSX.Element | ReactElement<any, any> /*| JSX.ElementClass | JSX.ElementAttributesProperty; */
    startIcon?: ReactNode
  }[]
}

export enum ACTION {
  VIEW,
  EDIT,
  DELETE,
  FORMVIEW,
  GUIDELINE,
  QRCODE,
  TIMEVIEW
}

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum USER_TYPES {
  ADMIN = 'SUPER_ADMIN',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  ADMIN_EMPLOYEE = 'USER',
  CLIENT_EMPLOYEE = 'CLIENT_EMPLOYEE',
  CONTRACTOR_EMPLOYEE = 'CONTRACTOR_EMPLOYEE',
}

export enum COMPANY_TYPES {
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
}

export class StateModel {
  public Auth!: InitialAuthStateModel;
  public Config!: InitialConfigStateModel;
}

export abstract class BaseModel {
  [key: string]: any;
  public createdAt?: string;
  public updatedAt?: string;
  public deletedAt?: string;
}

export class UploadModel extends BaseModel {
  public id?: number;
  public addedBy?: number;
  public mimeType?: string;
  public name?: string;
  public originalName?: string;
  public path?: string;
}

export class DashboardMetricModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public colors!: string;
  public icon?: SvgIconComponent;
  public title!: string;
  public totalNumber?: string | number;
  public childrens?: Array<DashboardMetricModel>;
  public to?: string;
}

export class ActivityListModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public id?: Number;
  public updaterModel?: String;
  public updaterId?: Number;
  public actionType?: String;
  public msg?: String;
}

export class ServiceHistoryVisitModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public id?: Number;
  public checkInAt?: String;
  public checkOutAt?: String;
  public historyId?: Number;
  public locationId?: Number;
  public userId?: Number;
  public time?: String;
}