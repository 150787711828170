import { GetDateTime } from 'Helpers/String';
import { ActivityListModel, BaseStateModel, DataTableModel, EmployeeModel, ServiceModel } from '../Models';

export class EmployeeListState extends BaseStateModel {
	public isDialogOpen: boolean = false;
	public employeeCsv: Array<EmployeeModel> = [];
	public downloadSample: CsvSample = {};
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: '/employees?with[]=company&with[]=services',
		headers: [
			{
				key: 'firstName',
				label: 'Employee Name',
				isSort: true,
				render: (item: EmployeeModel) => `${item.firstName || ''} ${item.lastName || ''}`
			},
			{ key: 'email', label: 'Email', isSort: false },
			{ key: 'phone', label: 'Phone', isSort: false },
			{ key: 'company.name', label: 'Company Name', isSort: true },
			{ key: 'service', label: 'Service Type', isSort: false,render: (item: EmployeeModel) => item.services?.map((item:ServiceModel) => item.name).join(',')	 },
			{ key: 'abn', label: 'ABN', isSort: true },
			{
				key: 'createdAt',
				label: 'Member Since',
				isSort: true,
				render: (item: EmployeeModel) => GetDateTime(item.createdAt)
			}
		],
		filters: [
			{
				name: 'Status',
				key: 'status',
				items: [
					{ label: 'Active', value: 'ACTIVE' },
					{ label: 'Inactive', value: 'INACTIVE' },
					{ label: 'Invited', value: 'DRAFT' }
				],
				multiple: false
			},
		]
	};
}

export class InviteEmployeeState extends BaseStateModel {
	public loading: boolean = false;
	public locations: Array<LocationData> = [];
	public services: Array<ServiceData> = [];
	public formErr?: { [key: string]: any } = {};
	public activity?: Array<ActivityListModel> = [];
	public employee: EmployeeModel = {
		name: '',
		abn: '',
		email: '',
		phone: '',
		service: [],
		locations: [],
		companyName: '',
		licenses: [],
		insurences: [],
	};
}

export class LocationData {
	public id?: number;
	public name?: string;
	public address?: string;
}

export class ServiceData {
	id?: number;
	name?: string;
}

export class EmployeeProfileState extends BaseStateModel {
	public loading: boolean = false;
	public employee: EmployeeModel = {
		name: '',
		abn: '',
		email: '',
		phone: '',
		trade: '',
		locations: [],
		companyDetails: {},
		tradeDetails: [],
		services:[]
	};
}

export class CsvUploadState extends BaseStateModel {
	public loading: boolean = false;
	public employees: Array<any> = [];
	public failedRows: Array<Number> = [];
	public downloadSample: CsvSample = {};
}

export class CsvSample {
	public extName?: string;
	public name?: string;
	public path?: string;
	public size?: number;
	public type?: string;
}