import BaseService from './BaseService';
import { Http } from '../Http';
import { LoadUser, LoadDeviceToken, Logout } from '../Redux/Actions';
import { LocationModel, Response, ResponseList, UserModel } from 'Redux/Models';
import { Dispatch } from '@reduxjs/toolkit';
import { deleteDeviceToken } from 'Firebase';

export default class AuthService extends BaseService {
	public static login(params: Object): Promise<Response<UserModel>> {
		return new Promise((resolve, reject) => {
			Http.post('/auth/login', params)
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static logOut(payload: Object): Function {
		return (dispatch: Dispatch) =>
			new Promise((resolve, reject) => {
				Http.post('/auth/log-out', payload)
					.then((res) => {
						if (res?.data.success) {
							deleteDeviceToken()
							dispatch(Logout())
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static addDeviceToken(params: { token: string }): Function {
		return (dispatch: Dispatch) =>
			new Promise((resolve, reject) => {
				Http.post('/auth/device-token', params)
					.then((res) => {
						dispatch(LoadDeviceToken(params.token));
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static register(params: Object = {}): Promise<Response<UserModel | { session: { url: string } }>> {
		return new Promise((resolve, reject) => {
			Http.post('/auth/register', params)
				.then((res) => {
					// setTimeout(() => {
					// 	dispatch(Login(res?.data.data));
					// }, 100);
					const data = new Response<{ user: UserModel }>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getMe(): Function {
		return (dispatch: Function) =>
			new Promise((resolve, reject) => {
				Http.get('/auth/me')
					.then((res) => {
						if (res?.data.status === 200 || res?.data.status === 205) {
							dispatch(LoadUser(res?.data.data));
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static getUserByToken(token: string): Promise<Response<UserModel>> {
		return new Promise((resolve, reject) => {
			Http.get('/auth/invite/' + token)
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateProfile(params: Object): Function {
		return (dispatch: Function) =>
			new Promise((resolve, reject) => {
				Http.put('/auth/profile', params)
					.then((res) => {
						if (res?.data.status === 200) {
							dispatch(LoadUser(res?.data.data));
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static updatePassword(params: Object): Promise<Response<{}>> {
		return new Promise((resolve, reject) => {
			Http.put('/auth/profile-password', params)
				.then((res) => {
					return resolve(new Response(res?.data));
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateProfileImage(params: Object): Function {
		return (dispatch: Function) =>
			new Promise((resolve, reject) => {
				Http.post('/auth/profile-image', params)
					.then((res) => {
						if (res?.data.status === 200) {
							dispatch(LoadUser(res?.data.data));
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static deleteProfileImage(params: Object = {}): Function {
		return (dispatch: Function) =>
			new Promise((resolve, reject) => {
				Http.delete('/auth/profile-image', { params })
					.then((res) => {
						if (res?.data.status === 200) {
							dispatch(LoadUser(res?.data.data));
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static resetPassword(params: Object): Promise<Response<{}>> {
		return new Promise((resolve, reject) => {
			Http.patch('/auth/reset-password', params)
				.then((res) => {
					return resolve(res?.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static forgetPassword(params: Object): Promise<Response<{}>> {
		return new Promise((resolve, reject) => {
			Http.post('auth/forget-password', params)
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static retryPayment(params: Object = {}): Promise<Response<{ session: { url: string } }>> {
		return new Promise((resolve, reject) => {
			Http.get('/auth/retry-payment', params)
				.then((res) => {
					return resolve(new Response(res?.data));
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static reinviteUser(id: number,): Promise<Response<UserModel>> {
		return new Promise((resolve, reject) => {
			Http.get('/reinvite/' + id)
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getMetrics(params: Object = {}): Promise<Response<{}>> {
		return new Promise((resolve, reject) => {
			Http.get('/auth/metrics', { params })
				.then((res) => {
					if (res?.data.status === 200) {
						let data = new Response<Response<{}>>(res.data)
						return resolve(data);
					}
				})
				.catch((err) => {
					return reject(err);
				})
		})
	}

	public static getSearchResults(params: Object = {}): Promise<ResponseList<UserModel | LocationModel>> {
		return new Promise((resolve, reject) => {
			Http.get('/auth/search', { params })
				.then((res) => {
					if (res?.data.status === 200) {
						let data = new ResponseList<UserModel | LocationModel>(res.data);
						return resolve(data);
					}
				})
				.catch((err) => {
					return reject(err);
				})
		})
	}

	public static increaseLoginCnt(params: Object = {}): Function {
		return (dispatch: Dispatch) =>
			new Promise((resolve, reject) => {
				Http.put('/auth/increment-login-count', { params })
					.then((res) => {
						if (res?.data.status === 200) {
							dispatch(LoadUser(res?.data.data));
						}
						return resolve(new Response(res?.data));

					})
					.catch((err) => {
						return reject(err);
					})
			})
	}

	public static uploadDocuments(params: Object = {}): Function {
		return (dispatch: Dispatch) =>
			new Promise((resolve, reject) => {
				Http.post('/employees/documents', params)
					.then((res) => {
						if (res?.data.status === 200) {
							dispatch(LoadUser(res?.data.data));
						}
						return resolve(new Response(res?.data));

					})
					.catch((err) => {
						return reject(err);
					})
			})
	}
}
