import { ChangeEvent, Component, Fragment, ReactNode } from 'react';
import {
	MuiTable,
	MuiTableContainer,
	MuiTablePagination,
	MuiTableBody,
	MuiTableCell,
	MuiTableHead,
	MuiTableRow,
	MuiTableSortLabel,
	MuiTooltip,
	MuiIconButton,
	MuiGrid,
	MuiInputField,
	MuiButton,
	// MuiCircularProgress,
	MuiBox,
	MuiListField,
	MuiInputAdornment
} from 'Components/MUI';

import { DataTableActionModel, DataTableHeaderModel, DataTableModel, States } from 'Redux/Models';
import { CancelTokenSource, CancelTokenStatic, Http } from 'Http';
import { GetValue } from 'Helpers/Object';
import { Map } from 'Helpers/Array';
import { AddIcon, CloseIcon, } from 'Helpers/Icons';
import { Theme } from '@mui/material/styles';
import { Loader } from 'Components';
import { RootContainer } from './styled';

export default class DataTable<T extends {}> extends Component<DataTableModel> {
	public state: States;
	public cancel: any;
	public cancelToken?: CancelTokenStatic;
	public source?: CancelTokenSource;
	public constructor(props: DataTableModel) {
		super(props);

		this.state = {
			loading: false,
			select: {
				all: false,
				items: []
			},
			actions: props.actions || [],
			items: [],
			total: 0,
			hasItem: false,
			pagination: props.pagination || false,
			params: {
				page: 1,
				limit: props.limit || 10,
				active: props.active || '_id',
				direction: props.direction || 'asc',
				q: ''
			}
		};
	}

	public componentDidMount() {
		this.getData();
	}

	public componentWillUnmount() {
		this.source?.cancel();
	}

	public initCancelToken = () => {
		this.cancelToken = Http.CancelToken;
		this.source = this.cancelToken.source();
	};

	public getData() {
		this.source?.cancel();
		const { url }: DataTableModel = this.props;
		this.setState((prevState: States) => (prevState.loading = true));
		const { params }: States = this.state;
		this.initCancelToken();
		if (url === '') {
			this.setState((prevState: States) => (prevState.loading = false));
		} else if (url) {
			Http.get(url, { params: params, cancelToken: this.source?.token })
				.then((resp) => {
					if (resp.status === 200) {
						this.setState((prevState: States) => {
							prevState.items = resp.data.data.items;
							prevState.total = resp.data.data.total;
							prevState.hasItem = resp.data.data.hasItem;
							prevState.loading = false;
							return prevState;
						});
					}
				})
				.catch(() => this.setState((prevState: States) => (prevState.loading = false)));
		};

	}

	public handleChangePage = (event: unknown, newPage: number) => {
		this.setState(
			(prevState: States) => ({
				params: {
					...prevState.params,
					page: newPage + 1
				}
			}),
			() => {
				this.getData();
			}
		);
	};

	public handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		const limit = parseInt(event.target.value, 10);
		this.setState(
			(prevState: States) => ({
				params: {
					...prevState.params,
					page: 1,
					limit: limit
				}
			}),
			() => {
				this.getData();
			}
		);
	};

	public createSortHandler = (property: String) => {
		const { params }: States = this.state;
		const isAsc = params.active === property && params.direction === 'asc';
		this.setState(
			(prevState: States) => ({
				params: {
					...prevState.params,
					page: 1,
					active: property,
					direction: isAsc ? 'desc' : 'asc'
				}
			}),
			() => {
				this.getData();
			}
		);
	};

	public handleAction = (action: DataTableActionModel, data: T) => {
		if (this.props.onActionClick) {
			this.props.onActionClick(action.key, data);
		}
	};

	public handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		this.setState(
			(prevState: States) => ({
				params: {
					...prevState.params,
					page: 1,
					q: event.target.value
				}
			}),
			() => {
				this.getData();
			}
		);
	};

	public handleClearSearch = () => {
		this.setState(
			(prevState: States) => ({
				params: {
					...prevState.params,
					page: 1,
					q: ''
				}
			}),
			() => {
				this.getData();
			}
		);
	};

	public handleFilter = (event: any) => {
		this.setState((prevState: States) => ({
			params: {
				...prevState.params,
				page: 1,
				[event.target.name]: event.target.value
			}
		}), () => this.getData());
	};

	public handleClearFilter = (event: any, key: string) => {
		if (key) {
			this.setState((prevState: States) => ({
				params: {
					...prevState.params,
					page: 1,
					[key]: null
				}
			}), () => this.getData());
		}
	};

	public render(): ReactNode {
		const { headers, onAddClick, searchable, newBtnTitle, filters, onUploadClick, uploadBtnTitle, gridColFull, moreBtns }: DataTableModel = this.props;
		const { items, total, params, hasItem, pagination, actions, loading }: States = this.state;
		return (
			<RootContainer>
				<MuiGrid container spacing={1} justifyContent="space-between" className="datatableMainGrid">
					{/* {!onAddClick && <MuiGrid item md={6} sm={6} xs={12}></MuiGrid>} */}

					{searchable && (
						<MuiGrid item md={4} sm={6} xs={12}>
							<MuiInputField
								size="small"
								onChange={this.handleSearch}
								value={params.q}
								placeholder="Search"
								InputProps={
									params.q
										? {
											endAdornment: (
												<MuiInputAdornment position="end">
													<MuiIconButton onClick={this.handleClearSearch}>
														<CloseIcon />
													</MuiIconButton>
												</MuiInputAdornment>
											)
										}
										: {}
								}
							/>
						</MuiGrid>
					)}

					{!!filters?.length && filters?.length > 0 && filters?.map((item) => (
						<MuiGrid key={item.key} item md={4} sm={6} xs={12}>
							<MuiListField
								name={item?.key}
								SelectProps={{ multiple: item.multiple }}
								label={item.name}
								defaultValue={item.multiple ? [] : ''}
								items={item?.items}
								value={params[item?.key] ? params[item?.key] : item.multiple ? [] : ''}
								onChange={this.handleFilter}
								InputProps={
									params[item?.key]
										? {
											endAdornment: (
												<MuiInputAdornment position="end">
													<MuiIconButton onClick={(e) => this.handleClearFilter(e, item.key)}>
														<CloseIcon />
													</MuiIconButton>
												</MuiInputAdornment>
											)
										}
										: {}
								}
							/>
						</MuiGrid>
					))}
					{(onAddClick || (moreBtns && moreBtns?.length > 0)) &&
						<MuiGrid item md={gridColFull ? 12 : 4} sm={12} xs={12} textAlign="right" className='tableTopBtnWrap'>
							{onAddClick && (
								<MuiButton loading={false} variant="contained" startIcon={<AddIcon />} onClick={() => onAddClick()}>
									{newBtnTitle ? newBtnTitle : 'New'}
								</MuiButton>
							)}
							{
								moreBtns?.map((moreBtn, idx) => (
									<Fragment key={idx}>
										{moreBtn.jsx ?
											moreBtn.jsx :
											<MuiButton
												sx={[(theme) => ({ ...theme.components?.MuiButton?.defaultProps?.sx, }), { ml: 2 },]}
												loading={false}
												startIcon={moreBtn.startIcon}
												onClick={moreBtn.onClick}
											>
												{moreBtn.label || 'Button'}
											</MuiButton>}
									</Fragment>
								))
							}
						</MuiGrid>
					}

					<MuiGrid item md={12} sm={12} xs={12}>
						<MuiTableContainer className='datatableMainWrap'>
							<MuiTable
								stickyHeader
								aria-label="sticky table"
								sx={[
									(theme: Theme) => ({
										my: 4,
										[theme.breakpoints.down('sm')]: {
											my: 2
										},
									})
								]}
							>
								<MuiTableHead>
									<MuiTableRow>
										{headers.map((header: DataTableHeaderModel, i: any) => (
											<MuiTableCell key={i} align={'left'} style={header.style}>
												{header.isSort ? (
													<MuiTableSortLabel active={params.active === header.key} direction={params.active === header.key ? params.direction : 'asc'} onClick={() => this.createSortHandler(header.key)}>
														<b style={{ whiteSpace: 'nowrap' }}>{header.label}</b>
													</MuiTableSortLabel>
												) : (
													<b style={{ whiteSpace: 'nowrap' }}>{header.label}</b>
												)}
											</MuiTableCell>
										))}
										{!!actions.length && (
											<MuiTableCell
												sx={{
													textAlign: 'center'
												}}
											>
												<b>Action</b>
											</MuiTableCell>
										)}
									</MuiTableRow>
								</MuiTableHead>
								{loading ? (
									<MuiTableBody>
										<MuiTableRow style={{ height: 33 }}>
											<MuiTableCell colSpan={actions.length ? 1 + headers.length : headers.length}>
												{/* <MuiCircularProgress size={25} thickness={4} /> */}
												<MuiBox sx={[
													(theme: Theme) => ({
														display: 'flex',
														justifyContent: 'center',
														padding: '50px 10px',
														[theme.breakpoints.down("md")]: {
															justifyContent: 'flex-start',
															padding: '50px 60px',
														},
														'& .loader': {
															width: 50,
															height: 50,
															'&:before': {
																width: 8,
																height: 8,
																top: '-5px',
															},
															'&:after': {
																width: 4,
																height: 4,
																top: 6,
																left: 'unset',
																transformOrigin: '-4px -4px',
															}
														},
													})
												]}>
													<Loader />
												</MuiBox>
											</MuiTableCell>
										</MuiTableRow>
									</MuiTableBody>
								) : (
									<MuiTableBody>
										{Map(items, (row: T, index: number) => {
											return (
												<MuiTableRow hover role="checkbox" tabIndex={-1} key={index}>
													{Map(headers, (header: DataTableHeaderModel, j: number) => {
														return (
															<MuiTableCell key={j} style={header.style}>
																{header?.render ? header.render(row) : GetValue(row, header.key) || ''}
															</MuiTableCell>
														);
													})}
													{actions && !!actions.length && (
														<MuiTableCell>
															<MuiBox display="flex" alignItems="center" justifyContent="center" className='tableActionsWrap'>
																{Map(actions, (action: DataTableActionModel, i: number) => (
																	<MuiTooltip key={i} title={action.tooltip || ''} arrow>
																		<MuiIconButton size="medium" onClick={() => this.handleAction(action, row)} color={action.color ? action.color : 'default'} sx={{ mr: 1 }}>
																			<action.icon fontSize="small" className="m-0" />
																		</MuiIconButton>
																	</MuiTooltip>
																))}
															</MuiBox>
														</MuiTableCell>
													)}
												</MuiTableRow>
											);
										})}
										{!hasItem && (
											<MuiTableRow style={{ height: 150 }}>
												<MuiTableCell colSpan={actions.length ? 1 + headers.length : headers.length} sx={[
													(theme: Theme) => ({
														textAlign: 'center',
														[theme.breakpoints.down("sm")]: {
															textAlign: 'left',
														},
													})
												]}
												>
													No data available
												</MuiTableCell>
											</MuiTableRow>
										)}
									</MuiTableBody>
								)}
							</MuiTable>
						</MuiTableContainer>
						{pagination && (
							<MuiTablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={total}
								rowsPerPage={params.limit}
								page={params.page - 1}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
								sx={[
									(theme: Theme) => ({
										[theme.breakpoints.down('sm')]: {
											'&.MuiTablePagination-root': {
												'padding': 0,
												'& .MuiTablePagination-toolbar': {
													padding: 0,
													justifyContent: 'flex-end'
												},
												'& .MuiTablePagination-spacer': {
													display: 'none'
												},
												'& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
													fontSize: 12
												},
												'& .MuiInputBase-root': {
													fontSize: 12,
													margin: '0 8px 0 5px'
												},
												'& .MuiTablePagination-actions': {
													'ml': 1,
													'& button': {
														padding: '2px'
													}
												}
											}
										}
									})
								]}
							/>
						)}
					</MuiGrid>
				</MuiGrid>
			</RootContainer>
		);
	}
}
