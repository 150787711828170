import React, { Component } from 'react'
import { InfoWindow } from "google-maps-react";
import { createRoot } from 'react-dom/client'

export default class InfoWindowWrap extends Component {
    constructor(props) {
        super(props);
        this.infoWindowRef = React.createRef();
        this.contentElement = document.createElement(`div`);
      }
    
      componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children) {
            const root=createRoot(this.contentElement)
            root.render(
            React.Children.only(this.props.children),
            
          );
          this.infoWindowRef.current.infowindow.setContent(this.contentElement);
        }
      }
    
      render() {
        return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
      }
}
