import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, /* ResponseList, */ EmployeeModel } from '../Redux/Models';

export default class EmployeeService extends BaseService {
	public static getEmployeeById(id: number, params: Object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/employees/' + id, { params, cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getList(params: Object = {}, options: Options = {}): Promise<Response<EmployeeModel[]>> {
		let url: string = '/employees/user-list';
		if (options?.root) {
			url = options.root + url;
		}
		EmployeeService.initCancelToken();
		if (options?.source) {
			EmployeeService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel[]>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static invite(params: Object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/employees', params, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateStatus(id: number, params: Object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/employees/' + id, params, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/employees/' + id, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static deleteDoc(id: number): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/employees/docs/' + id, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static showEmployeeById(id: number, params: Object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/employees/' + id, { params, cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateEmployee(id: number, params: object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/employees/' + id, params, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static inviteByCsv(params: Object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/employees/csv', params, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getCsvTemplate(): Promise<Response<any>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/employees/template')
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static verifyDocument(id: number, params: Object = {}): Promise<Response<EmployeeModel>> {
		EmployeeService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put(`/employees/docs/${id}`, params, { cancelToken: EmployeeService.source?.token })
				.then((res) => {
					let data = new Response<EmployeeModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		})
	}
}