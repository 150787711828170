import { BaseStateModel, DataTableModel, LocationModel, ServiceModel, ServiceHistoryModel, UserModel, ServiceHistoryVisitModel } from '../Models';

export class AddServiceHistoryStates extends BaseStateModel {
	public loading: boolean = false;
	public allLocations: Array<LocationModel> = [];
	public allServices: Array<ServiceModel> = [];
	public allUsers: Array<UserModel> = [];
	public serviceHistory: ServiceHistoryModel = {
		service: '',
		location: '',
		user: '',
		task: '',
	};
}

export class ServiceHistoryListState extends BaseStateModel {
	public modalOpen: boolean = false;
	public visitCounts: Array<ServiceHistoryVisitModel> = [];
	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'id',
		direction: 'desc',
		actions: [],
		url: 'service-history?with[]=creator.company&with[]=assignedUser&with[]=service&with[]=location&with[]=visit',
		headers: [
			{ key: 'assignedUser.name', label: 'Employee Name', isSort: false, render: (item: any) => `${item.assignedUser.firstName} ${item.assignedUser.lastName}` },
			{ key: 'service.name', label: 'Service Type', isSort: false, },
			{ key: 'location.name', label: 'Location', isSort: false, },
			{ key: 'creator.company.name', label: 'Company Name', isSort: false, },
			{ key: 'task', label: 'Task', isSort: true },
			// { key: 'checkIn', label: 'Check In', isSort: true, render: (item: any) => GetDateTime(item.checkIn) },
			// { key: 'checkOut', label: 'Check Out', isSort: true, render: (item: any) => GetDateTime(item.checkOut) },
			// { key: 'timeOnsite', label: 'Time Onsite', isSort: true, render: (item: any) => GetHourDifference(item.checkIn, item.checkOut) },
			// { key: 'induction', label: 'Induction', isSort: true },
		],
		filters: [
			{ key: 'serviceId', name: 'Service Type', items: [], multiple: true },
			{ key: 'locationId', name: 'Locations', items: [], multiple: true },
		]
	};
}

export class UploadedFilesDialogState extends BaseStateModel {
	public tab: '1' | '2' = '1'
}