import { GetDateTime, GetHourDifference } from "Helpers/String";
import { VisitHistory } from "Redux/Models/Location.Models";
import { INDUCTION_TYPES } from "Redux/Models/Service.History.Models";
import {
  BaseStateModel,
  DataTableModel,
  ServiceHistoryVisitModel,
  UploadModel,
} from "../Models";

export class ReportListState extends BaseStateModel {
  public modalOpen: boolean = false;
  public timeModalOpen: boolean = false;
  public visitCounts: Array<ServiceHistoryVisitModel> = [];
  public modalData?: { userId?: Number; locationId?: Number };
  public tab: number = 0;
  public files: { insurences: UploadModel[]; licenses: UploadModel[] } | null =
    null;
  public allHeaders = [
    { key: "user.name", label: "Name", isSort: false },
    {
      key: "user.type",
      label: "Type",
      isSort: false,
      render: (item: any) => item.user?.type,
    },
    { key: "location.name", label: "Location", isSort: false },
    { key: "location.owner.name", label: "Client", isSort: false },
    { key: "user.company.name", label: "Company", isSort: false },
    { key: "induction", label: "Induction", isSort: false },

    { key: "name", label: "Name", isSort: false },
    { key: "type", label: "Type", isSort: false },
    {
      key: "assignedLocations.0.name",
      label: "Location",
      isSort: false,
      render: (item: any) => item?.assignedLocations?.[0]?.name,
    },
    { key: "company.name", label: "Company", isSort: false },
    {
      key: "service",
      label: "Service",
      isSort: false,
      render: (item: any) => item.services?.[0]?.name,
    },
  ];

  public allFilters = [
    { key: "locationId", name: "Locations", multiple: true, items: [] },
    {
      key: "induction",
      name: "Induction Type",
      multiple: true,
      items: [
        { label: "Passed", value: INDUCTION_TYPES.PASSED },
        { label: "Failed", value: INDUCTION_TYPES.FAILED },
      ],
    },
    {
      key: "license",
      name: "License Status",
      multiple: true,
      items: [
        { label: "Expired", value: "EXPIRED" },
        { label: "Running", value: "RUNNING" },
      ],
    },
    {
      key: "insurance",
      name: "Insurance Status",
      multiple: true,
      items: [
        { label: "Expired", value: "EXPIRED" },
        { label: "Running", value: "RUNNING" },
      ],
    },
  ];

  public dataTable: DataTableModel = {
    searchable: false,
    pagination: true,
    limit: 10,
    active: "updatedAt",
    direction: "desc",
    actions: [],
    url: "check-ins/reports?with[]=user.company&with[]=location&with[]=checkIns",
    headers: [],
    filters: [],
    // headers: this.allHeaders.filter((d, i) => i < 7),
    // filters: this.allFilters.filter((d, i) => i < 2),
  };
}

export class CheckInCheckOutListState extends BaseStateModel {
  public allHeaders = [
    {
      key: "CheckInAt",
      label: "Check In At",
      isSort: true,
      render: (item: VisitHistory) => GetDateTime(item.checkInAt),
    },
    {
      key: "CheckOutAt",
      label: "Check Out At",
      isSort: true,
      render: (item: VisitHistory) => GetDateTime(item.checkOutAt) || "N/A",
    },
    { key: "time", label: "Time Spend", isSort: true },
  ];

  public dataTable: DataTableModel = {
    searchable: false,
    pagination: true,
    limit: 10,
    active: "createdAt",
    direction: "desc",
    actions: [],
    url: "check-ins?with[]=user.company&with[]=location",
    headers: this.allHeaders,
    filters: [],
  };
}

export class ContractorProfileState extends BaseStateModel {
  public loading: boolean = false;
  public edit: boolean = false;
  public tab: number = 0;

  public dataTable: DataTableModel = {
    pagination: true,
    searchable: true,
    limit: 10,
    active: "createdAt",
    direction: "desc",
    actions: [],
    url: "locations",
    headers: [
      { key: "name", label: "Building Name/Referance", isSort: true },
      {
        key: "address",
        label: "Address",
        isSort: true,
        style: { minWidth: "200px" },
      },
      { key: "company", label: "Company name", isSort: true },
      { key: "contactName", label: "Contact Name", isSort: true },
      { key: "contactEmail", label: "Contact Email", isSort: true },
      { key: "contactPhone", label: "Contact Phone", isSort: true },
    ],
  };

  public empDataTable: DataTableModel = {
    pagination: true,
    searchable: true,
    limit: 10,
    active: "createdAt",
    direction: "desc",
    actions: [],
    url: "employees",
    headers: [
      { key: "firstName", label: "First Name", isSort: true },
      { key: "lastName", label: "Last Name", isSort: true },
      { key: "email", label: "Email", isSort: true },
      { key: "phone", label: "Phone", isSort: true },
    ],
  };
}
