import { Component, ReactNode } from "react";
import { BlackLogo } from "Helpers/Images";
import { AuthLayoutContainer } from "./styled";
import { MuiBox, MuiButton } from "Components/MUI";
import { Redirect, Route, Switch } from "react-router-dom";
import { Map } from "Helpers/Array";
import { RouteInterface } from "Routes";
import { Props } from "Redux/Models";
import { Theme } from "@mui/material/styles";

export default class AuthLayout extends Component<Props> {
  public render(): ReactNode {
    const { childrens, ...restProps }: Props = this.props;
    return (
      <AuthLayoutContainer>
        <MuiBox
          display="flex"
          justifyContent="space-between"
          sx={[
            (theme: Theme) => ({
              width: "80%",
              margin: "0 auto",
              position: "relative",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
              "& img": {
                width: 155,
              },
              "& button": {
                boxShadow: "none",
              },
            }),
          ]}
        >
          <a href="https://selrqr.com.au/" target="_blank" rel="noreferrer">
            <img alt="logo" src={BlackLogo} />
          </a>

          <a href="https://selrqr.com.au/?getDemoModal" target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
            <MuiButton
              loading={false}
              variant="outlined"
              fullWidth={false}
              className="bookADemoBtn"
            >
              Book a demo
            </MuiButton>
          </a>
        </MuiBox>

        <MuiBox
          sx={[
            (theme: Theme) => ({
              [theme.breakpoints.down("lg")]: {
                mb: 1.5,
              },
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
              [theme.breakpoints.up("xl")]: {
                '& .fieldTopLabel': {
                  fontSize: '16px !important',
                  '& .linkOnFormLabel': {
                    fontSize: '16px !important',
                  }
                }
              },
            }),
            {
              position: "relative",
              mt: 0,
              height: "100%",
              minHeight: "inherit",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: 'center',
              '& .fieldTopLabel': {
                fontSize: 14,
                color: '#3C4257',
                marginBottom: '10px',
                display: 'block',
                '& .linkOnFormLabel': {
                  fontSize: 14,
                  color: 'primary.main',
                  marginLeft: 0.5,
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }
              }
            }
          ]}
        >
          <MuiBox className="AuthFormMainWrapper">
            <Switch>
              {Map(childrens, (route: RouteInterface, i) => {
                return (
                  <Route
                    key={i}
                    exact={route.exact}
                    path={route.path}
                    render={(rProps: any) =>
                      !route.redirect ? (
                        <route.component
                          {...restProps}
                          {...rProps}
                          childrens={route.childrens}
                        />
                      ) : (
                        <Redirect to={route.redirect || "/"} />
                      )
                    }
                  />
                );
              })}
            </Switch>
          </MuiBox>
        </MuiBox>
      </AuthLayoutContainer>
    );
  }
}
