import { MuiBox, MuiButton, MuiGrid, MuiIconButton, MuiInputField } from 'Components/MUI';
import { Component, ReactNode } from 'react';
import { DialogTitle, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { StyleDialog } from './styled';
import { Formik, FormikHelpers } from 'formik';
import { InviteClientValidator } from 'Helpers/Validator';
import { UserModel } from 'Redux/Models';
import { InviteClientState } from 'Redux/States';
import { ClientService } from 'Services';
import { CloseIcon } from 'Helpers/Icons';
import { BlockPhoneKeys } from 'Helpers/String';

class InviteClient extends Component<DialogProps, InviteClientState> {
	public state: InviteClientState = new InviteClientState();

	public handleSubmit = (values: UserModel & { address: string }, actions: FormikHelpers<UserModel & { address: string }>) => {
		const { onClose } = this.props;
		actions.setSubmitting(true);
		ClientService.invite(values)
			.then((resp) => {
				if (resp.isSuccess()) {
					if (onClose) {
						onClose(resp.getData(), 'backdropClick');
					}
				}
				actions.setSubmitting(true);
			})
			.catch(() => actions.setSubmitting(false));
	};

	public render(): ReactNode {
		const { client } = this.state;
		const { onClose } = this.props;
		return (
			<StyleDialog {...this.props}>
				<Formik initialValues={client} enableReinitialize={true} validationSchema={InviteClientValidator} onSubmit={this.handleSubmit}>
					{(props) => (
						<MuiBox component="form" noValidate autoComplete="off" onSubmit={props.handleSubmit}>
							<DialogTitle>
								Invite Client
								{onClose &&
									<MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
										<CloseIcon />
									</MuiIconButton>
								}
							</DialogTitle>
							<DialogContent sx={{ mt: 1, pt: '8px !important' }}>
								<MuiGrid container spacing={2}>
									<MuiGrid item md={6} xs={12}>
										<MuiInputField
											name="firstName"
											label="First Name"
											onChange={props.handleChange}
											value={props.values.firstName || ''}
											onBlur={props.handleBlur}
											error={props.touched.firstName && !!props.errors.firstName}
											helperText={props.touched.firstName ? props.errors.firstName : ''}
										/>
									</MuiGrid>
									<MuiGrid item md={6} xs={12}>
										<MuiInputField
											name="lastName"
											label="Last Name"
											onChange={props.handleChange}
											value={props.values.lastName || ''}
											onBlur={props.handleBlur}
											error={props.touched.lastName && !!props.errors.lastName}
											helperText={props.touched.lastName ? props.errors.lastName : ''}
										/>
									</MuiGrid>
									<MuiGrid item md={6} xs={12}>
										<MuiInputField
											name="email"
											label="Email"
											onChange={props.handleChange}
											value={props.values.email || ''}
											onBlur={props.handleBlur}
											error={props.touched.email && !!props.errors.email}
											helperText={props.touched.email ? props.errors.email : ''}
										/>
									</MuiGrid>
									<MuiGrid item md={6} xs={12}>
										<MuiInputField
											name="phone"
											label="Phone"
											onChange={props.handleChange}
											onKeyDown={BlockPhoneKeys}
											value={props.values.phone || ''}
											onBlur={props.handleBlur}
											error={props.touched.phone && !!props.errors.phone}
											helperText={props.touched.phone ? props.errors.phone : ''}
										/>
									</MuiGrid>
									<MuiGrid item md={6} xs={12}>
										<MuiInputField
											name="companyName"
											label="Company Name"
											onChange={props.handleChange}
											value={props.values.companyName || ''}
											onBlur={props.handleBlur}
											error={props.touched.companyName && !!props.errors.companyName}
											helperText={props.touched.companyName ? props.errors.companyName : ''}
										/>
									</MuiGrid>
									<MuiGrid item md={6} xs={12}>
										<MuiInputField
											name="abn"
											label="ABN"
											onChange={props.handleChange}
											value={props.values.abn || ''}
											onBlur={props.handleBlur}
											error={props.touched.abn && !!props.errors.abn}
											helperText={props.touched.abn ? props.errors.abn : ''}
										/>
									</MuiGrid>
									<MuiGrid item xs={12}>
										<MuiInputField
											multiline
											rows={3}
											name="address"
											label="Address"
											onChange={props.handleChange}
											value={props.values.address || ''}
											onBlur={props.handleBlur}
											error={props.touched.address && !!props.errors.address}
											helperText={props.touched.address ? props.errors.address : ''}
										/>
									</MuiGrid>
								</MuiGrid>
							</DialogContent>
							<DialogActions sx={{ pb: 3, px: 3 }}>
								<MuiButton
									type="submit"
									loading={props.isSubmitting}
									fullWidth={false}
									variant="contained"
									sx={[
										(theme: any) => ({
											...theme.components?.MuiButton?.defaultProps?.sx
										}),
										{
											fontSize: '16px',
											bgcolor: 'primary.main',
											color: 'primary.contrastText'
										}
									]}
								>
									Invite
								</MuiButton>
							</DialogActions>
						</MuiBox>
					)}
				</Formik>
			</StyleDialog>
		);
	}
}
export default InviteClient;
