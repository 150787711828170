import { DashboardMetricModel, FIELD, GENDER, MODULE } from 'Redux/Models';
import {
  AdjustRoundedIcon, CheckCircleIcon,
} from 'Helpers/Icons';
import { FormsIcon, GlobeIcon, ShopIcon, UserIcon, VisitIcon } from 'Helpers/Images';

export const GENDERS = [
  {
    label: 'Male',
    value: GENDER.MALE,
  },
  {
    label: 'Female',
    value: GENDER.FEMALE,
  },
  {
    label: 'Others',
    value: GENDER.OTHER,
  },
];

export const FIELDS = [
  {
    label: 'Text',
    value: FIELD.TEXT,
  },
  {
    label: 'Textarea',
    value: FIELD.TEXTAREA,
  },
  {
    label: 'Single choice',
    value: FIELD.RADIO,
  },
  {
    label: 'Multiple choice',
    value: FIELD.CHECKBOX,
  },
  {
    label: 'Image/document',
    value: FIELD.FILE,
  },
  {
    label: 'Signature',
    value: FIELD.SIGNATURE,
  },
];

export const CONTRACTORFILE = [
  {
    type: 'image',
  },
  {
    type: 'application',
  },
];

export const ALOW_LICENSE_FORMATS = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
];

export const ALOW_UPLOAD_FORMATS = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
];

export const ALLOW_UPLOAD_IMAGE = ["image/png", "image/jpg", "image/jpeg"];

export const DASHBOARD_METRICS: Array<DashboardMetricModel> = [
  {
    title: 'Visits',
    image: VisitIcon,
    colors: '#ff8d00',
    totalNumber: 0,
    name: 'visits',
    module: [],
    to: '/service-history'
  },
  {
    title: 'Employees',
    image: UserIcon,
    colors: '#FF5F53',
    totalNumber: 0,
    name: 'employees',
    module: [MODULE.EMPLOYEE_MANAGEMENT],
    to: '/employees'
  },
  {
    title: 'Locations',
    image: ShopIcon,
    colors: '#405062',
    totalNumber: 0,
    name: 'locations',
    module: [MODULE.LOCATION_MANAGEMENT],
    to: '/locations'
  },
  {
    title: 'Contractors',
    image: UserIcon,
    colors: '#4D4949',
    totalNumber: 0,
    name: 'contractors',
    module: [MODULE.CONTRACTOR_MANAGEMENT],
    to: '/contractors'
  },
  {
    title: 'Clients',
    image: GlobeIcon,
    colors: '#14AE5C',
    totalNumber: 0,
    name: 'clients',
    module: [MODULE.CLIENT_MANAGEMENT],
    to: '/clients'
  },
  {
    title: 'Users',
    image: UserIcon,
    colors: '#EDBB01',
    totalNumber: 0,
    name: 'users',
    module: [MODULE.USER_MANAGEMENT],
    to: '/users'
  },
  {
    title: 'Forms',
    image: FormsIcon,
    colors: '#D56C4E',
    totalNumber: 0,
    name: 'forms',
    module: [MODULE.LOCATION_MANAGEMENT],
    to: '/locations'
  },
];

export const PLANS_ICON_LIST = [
  {
    icon: <CheckCircleIcon />,
    primaryText: 'Check-in & Check-out forms'
  },
  {
    icon: <CheckCircleIcon />,
    primaryText: 'Activity log Dashboard'
  },
  {
    icon: <CheckCircleIcon />,
    primaryText: '30 x Visitor companies'
  },
  {
    icon: <CheckCircleIcon />,
    primaryText: '50 x daily check-ins'
  }
];


export const ICON_LIST = [
  {
    icon: <AdjustRoundedIcon />,
    primaryText: 'Check-in & Check-out forms',
  },
  {
    icon: <AdjustRoundedIcon />,
    primaryText: 'Activity log Dashboard',
  },
  {
    icon: <AdjustRoundedIcon />,
    primaryText: '30 x Visitor companies',
  },
  {
    icon: <AdjustRoundedIcon />,
    primaryText: '50 x daily check-ins',
  },
];


export const PRIMARY_SECONDARY_LIST = [
  {
    id: 1,
    label: 'Lorem ipsum',
    secondaryText: 'Simply dummy',
  },
  {
    id: 2,
    label: 'Simply dummy',
    secondaryText: 'Lorem ipsum lorem',
  },
  {
    id: 3,
    label: 'Ipsum text',
    secondaryText: 'Text lorem dummy',
  },
];

export const HEADING_PARA_LIST = [
  {
    id: 1,
    type: 'text',
    heading: 'Point of using Lorem Ipsum?',
    para: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  },
  {
    id: 2,
    type: 'multipleChoice',
    heading: 'Consectetur adipisicing elit?',
    childrens: [
      {
        id: 1,
        label: 'Multiple choice 1'
      },
      {
        id: 2,
        label: 'Multiple choice 2'
      },
      {
        id: 3,
        label: 'Multiple choice 3'
      },
    ],
  },
  {
    id: 3,
    type: 'text',
    heading: 'Sit amet consectetur?',
    para: 'Lorem ipsum dolor.',
  },
  {
    id: 4,
    type: 'text',
    heading: 'What is Lorem Ipsum?',
    para: 'It is a long established fact.',
  },
  {
    id: 5,
    type: 'radio',
    heading: 'Trade/Visitor type?',
    para: 'Consectetur adipisicing Lorem ipsum',
  },
  {
    id: 6,
    type: 'multipleChoice',
    heading: 'Consectetur adipisicing elit?',
    childrens: [
      {
        id: 1,
        label: 'Lorem ipsum dolor sit amet'
      },
      {
        id: 2,
        label: 'psum dolor sit amet consectetur adipisicing elit'
      },
      {
        id: 3,
        label: 'Multiple choice 3'
      },
    ],
  },
  {
    id: 7,
    type: 'text',
    heading: 'Trade/Visitor type?',
    para: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  },
]

export const FILTER_METRICS = [
  { label: 'Today', value: 1 },
  { label: '7 days', value: 7 },
  { label: '1 month', value: 30 },
  { label: '3 month', value: 90 },
  { label: '6 month', value: 180 },
  { label: '1 year', value: 365 },
]

enum INDUCTION {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}

export enum SERVICE_STATUSES {
	COMPLETED = 'COMPLETED',
	PENDING = 'PENDING',
}

export const INDUCTION_STATUS = [
  { label: 'Passed', value: INDUCTION.PASSED },
  { label: 'Failed', value: INDUCTION.FAILED },
]

export const SERVICE_HISTORY_STATUS = [
  { label: 'Pending', value: SERVICE_STATUSES.PENDING  },
  { label: 'Completed', value: SERVICE_STATUSES.COMPLETED }
]