import { Theme } from '@mui/material';
import { MuiBox, MuiCard, MuiGrid, MuiTypography } from 'Components/MUI';
import { DASHBOARD_METRICS } from 'Configs/Constants';
import { Map } from 'Helpers/Array';
import { BgDots } from 'Helpers/Images';
import { Component, Fragment, ReactNode } from 'react';
import { DashboardMetricModel, PERMISSION, Props } from 'Redux/Models';

class DashBoardMetrics extends Component<Props> {
	public metricsSort = () => {
		const { metrics } = this.props;
		Map(DASHBOARD_METRICS, (metric) => {
			switch (metric.name) {
				case 'visits': {
					metric.totalNumber = metrics?.totalVisits || 0;
					break;
				}
				case 'employees': {
					metric.totalNumber = metrics?.totalEmployee || 0;
					break;
				}
				case 'locations': {
					metric.totalNumber = metrics?.totalLocations || 0;
					break;
				}
				case 'contractors': {
					metric.totalNumber = metrics?.totalContractor || 0;
					break;
				}
				case 'clients': {
					metric.totalNumber = metrics?.totalClient || 0;
					break;
				}
				case 'users': {
					metric.totalNumber = metrics?.totalUser || 0;
					break;
				}
				case 'forms': {
					metric.totalNumber = metrics?.totalLocations ? metrics?.totalLocations * 3 : 0;
					break;
				}
			};
		});
	}

	public render(): ReactNode {
		this.metricsSort();
		const { user, history } = this.props;
		return (
			<Fragment>
				{Map(DASHBOARD_METRICS, (list: DashboardMetricModel, listIndex) => (
					<Fragment key={listIndex}>
						{(list.module.length < 1 || user.can(list.module, PERMISSION.VIEW)) &&
							<MuiGrid item xl={2} md={2} sm={4} xs={6}>
								<MuiCard
									onClick={list.to ? () => history.push(list.to!) : undefined}
									sx={[
										(theme: Theme) => ({
											cursor: list.to ? 'pointer' : 'default',
											p: 2.5,
											height: '100%',
											borderRadius: '8px',
											backgroundColor: `${list.colors}`,
											position: 'relative',
											[theme.breakpoints.down('md')]: {
												p: 1.5,
											},
											'& .dotBg': {
												position: 'absolute',
												top: 5,
												left: 5,
												width: '85px !important',
											},
											'& .bottomDotBg': {
												bottom: 5,
												right: 5,
												ml: 'auto',
												mt: 'auto',
											}
										})
									]}>
									<img src={BgDots} alt="dot bg" className='dotBg topDotBg' />
									<img src={BgDots} alt="dot bg" className='dotBg bottomDotBg' />
									<MuiBox textAlign='right' sx={[
										(theme: Theme) => ({
											'& img': {
												height: 25,
												width: 'auto !important',
											}
										})
									]}>
										<img src={list.image} alt="list" />
									</MuiBox>
									<MuiBox mt={2}>
										<MuiTypography variant='h3' color='primary.contrastText' fontWeight={700} mb={1}>
											{list.totalNumber || 0}
										</MuiTypography>
										<MuiTypography variant='caption' color='primary.contrastText' textTransform='uppercase' sx={[
											(theme: Theme) => ({
												fontSize: 14,
												[theme.breakpoints.down('md')]: {
													fontSize: 12,
												}
											})
										]}>
											{list.title}
										</MuiTypography>
									</MuiBox>
								</MuiCard>
							</MuiGrid>
						}
					</Fragment>
				))}
			</Fragment>
		)
	}
}

export default DashBoardMetrics;