import { StateModel } from "../Models";

export const storageContainer =
  process.env.NODE_ENV === "development" ? sessionStorage : localStorage;
//export const storageContainer = localStorage;
export default class Stroage {
  public static LoadState = (): StateModel => {
    try {
      const sessionState: any = storageContainer.getItem("state") || {};
      const state: StateModel = Object.assign({}, JSON.parse(sessionState));
      return state;
    } catch (err) {
      const params: StateModel = new StateModel();
      return Object.assign({}, params);
    }
  };

  public static SaveState = (state: StateModel): void => {
    try {
      storageContainer.clear();
      const sessionState = {
        Auth: state.Auth,
        Config: state.Config,
      };
      storageContainer.setItem("state", JSON.stringify(sessionState));
    } catch (err) {
      // console.error(err);
    }
  };
}
