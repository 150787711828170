import { MuiBox, MuiButton, MuiTypography } from 'Components/MUI'
import { Theme } from '@mui/material/styles';
import React, { Component } from 'react'
import { NoPlanSelectedImg } from 'Helpers/Images';
import { Props, ROLE } from 'Redux/Models';

export default class NoSubscriptionContainer extends Component<Props> {
    public takeSubscription=()=>{
        const {user,history}=this.props
        if(user.subscribed)
            history.push('/upgrade')
        else
            history.push('/plans')
    }
    render() {
        return (
            <MuiBox
                sx={[
                    (theme: Theme) => ({
                        backgroundColor: 'primary.contrastText',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        padding: '30px 20px',
                        boxShadow: '0 0 15px #0000001a',
                        minHeight: '82vh',
                        [theme.breakpoints.down("sm")]: {
                            padding: '20px',
                        },
                        '& img': {
                            maxHeight: '350px',
                            maxWidth: '100%',
                            margin: '0 0 15px 0',
                            [theme.breakpoints.down("sm")]: {
                                maxHeight: '250px',
                            },
                        }
                    })
                ]}
            >
                <img src={NoPlanSelectedImg} alt="No plan" />
                
                <MuiTypography variant='h5' sx={{
                    color: 'secondary.dark',
                    mt: 1.5,
                    mb: 4,
                }}>
                    {
                        (this.props.user.hasRole(ROLE.CLIENT) && this.props.user.subscribed === 1 && !this.props.user.subscriptions?.length)?
                        'Looks like your subscription is over,please resubscribe':
                        'Please complete the subscription.'
                    }
                    
                </MuiTypography>

                <MuiButton onClick={this.takeSubscription}>Click here to {this.props.user.subscribed? "resubscribe":'subscribe'}</MuiButton>
            </MuiBox>
        )
    }
}
