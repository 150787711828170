import Configs from 'Configs';
import { KeyboardEvent } from 'react';
import { storageContainer } from 'Redux/Stroage';

export const GetImage = (img?: string): string => {
	if (!img) {
		return '';
	}
	return Configs.API_ROOT + '/' + img;
};

export const getFullPath = (path?: string): string => {
	if (!path) {
		return '';
	}
	return Configs.API_ROOT + '/' + path;
};

export const GetDateTime = (date?: string | Date) => {
	if (!date) {
		return '';
	}
	const utcDate = new Date(date);
	return utcDate.toISOString().slice(0, 10).split('-').reverse().join('/') + ' ' + utcDate.toLocaleTimeString();
};

export const GetHourDifference = (startDate?: string | Date, endDate?: string | Date) => {
	if (startDate && endDate) {
		const timeStart = new Date(startDate).getTime();
		const timeEnd = new Date(endDate).getTime();
		const milliDiff = timeEnd - timeStart; //in ms
		const minDiff = milliDiff / 60 / 1000; //in minutes
		const hourDiff = milliDiff / 3600 / 1000; //in hours
		const result = ((Math.floor(hourDiff) > 0 && Math.floor(hourDiff) + (Math.floor(hourDiff) > 1 ? ' hours ' : ' hour ')) || '') + ' ' +
			((Math.round(minDiff - 60 * Math.floor(hourDiff)) + (Math.round(minDiff - 60 * Math.floor(hourDiff)) > 1 ? ' minutes ' : ' minute ')) || '');
		return result;
	}
	return '';
}

export const GetDate = (date?: string | Date) => {
	if (!date) {
		return '';
	}
	const result = new Date(date).toLocaleDateString('pt-br').split('/').reverse().join('-');
	return result;
};

export const TimeSince = (date: any) => {
	let seconds = Math.floor((Date.now() - date) / 1000);
	let unit = "second";
	let direction = "ago";
	if (seconds < 30) {
		return direction = "Just now";
	}
	let value = seconds;
	if (seconds >= 31536000) {
		value = Math.floor(seconds / 31536000);
		unit = "year";
	} else if (seconds >= 86400) {
		value = Math.floor(seconds / 86400);
		unit = "day";
	} else if (seconds >= 3600) {
		value = Math.floor(seconds / 3600);
		unit = "hour";
	} else if (seconds >= 60) {
		value = Math.floor(seconds / 60);
		unit = "minute";
	}
	if (value !== 1)
		unit = unit + "s";
	return value + " " + unit + " " + direction;
}

export const IsDateExpired = (date: Date | string) => {
	if (!date) {
		return '';
	}
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return date < today;
}

export const compareDates = (dateOne: string | number, dateTwo = Date.now()) => {
	const date1 = new Date(dateTwo).getTime();
	const date2 = new Date(dateOne).getTime();
	if (date1 < date2) {
		return "LESSER";
	} else if (date1 > date2) {
		return 'GREATER';
	} else if (date1 === date2) {
		return 'EQUAL';
	}
};

export const getStateFromStorage = () => {
	const localState = storageContainer.getItem('state');
	let parsedData;
	if (localState) {
		parsedData = JSON.parse(localState);
	}
	return parsedData
}

export const isSessionExpired = () => {
	const { Auth: { lastLoggedIn, user } } = getStateFromStorage();
	const lastLogin = new Date(lastLoggedIn).getTime();
	const timeNow = Date.now();
	if (compareDates(lastLogin, Date.now()) === 'GREATER') {
		if (user && ((new Date(timeNow).valueOf() - lastLogin) > 1000 * 60 * 60 * 168)) {
			return true;
		} else {
			return false;
		}
	}
};

export const sessionDayCount = () => {
	const { Auth: { lastLoggedIn } } = getStateFromStorage();
	const lastLogin = new Date(lastLoggedIn).getTime();
	const timeNow = Date.now();
	const difference = (new Date(timeNow).valueOf() - lastLogin) > 1000 * 60 * 60 * 24;
	return difference;
}

export const BlockPhoneKeys = (event: KeyboardEvent) => {
	if (!event) return '';

	const allowedKeys = [8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

	const { keyCode } = event;

	if (!allowedKeys.includes(keyCode)) {
		event.preventDefault();
		return;
	}

}