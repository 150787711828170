import { Dialog, styled as MuiStyle, Theme } from '@mui/material';
import { ThemeProps } from 'styled-components';

export const StyleDialog = MuiStyle(Dialog)(({ theme }: ThemeProps<Theme>) => ({
    '&.bigModalMainWrap': {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                maxWidth: '100%',
            },
        }
    },
    '.MuiDialogTitle-root': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '& .MuiPaper-root': {
        width: 700,
        [theme.breakpoints.down('md')]: {
            width: 550,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    '&.checkInOutModalMainWrap': {
        maxHeight: 'initial',
        overflowY: 'unset',
        '& .MuiPaper-root': {
            [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: '100%',
            },
        },
        '& .MuiDialogContent-root': {
            maxHeight: '90vh',
            marginTop: 12,
        },
        '& .datatableMainWrap': {
            overflow: 'auto',
            maxHeight: 300,
            '& table': {
                marginTop: 0,
                '& th, td': {
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                    },
                }
            },
        }
    }
}));
