import { BaseStateModel, DataTableModel, ContractortModel, LocationModel, ServiceModel, NotesModel, ActivityListModel } from '../Models';

export class AddContractorState extends BaseStateModel {
	public loading: boolean = false;
	public allLocations: Array<LocationModel> = [];
	public allServices: Array<ServiceModel> = [];
	public contractor: ContractortModel = {
		companyName: '',
		service: [],
		licenseReq: false,
		insuarenceReq: false,
		contactName: '',
		email: '',
		phone: '',
		locations: [],
		invite: '',
		licenseExp: new Date(),
		insuarenceExp: new Date(),
		license: null,
		insurence: null,
		company: {}
	};
}

export class ContractorListState extends BaseStateModel {
	public modalOpen: boolean = false;
	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'contractors?with[]=services&with[]=locations&with[]=company.uploadedLicense&with[]=company.uploadedLicense&with[]=assignedLocations&with[]=employees',
		headers: [
			{ key: 'company.name', label: 'Company', isSort: true },
			{
				key: 'firstName',
				label: 'Name',
				isSort: true,
				render: (item: ContractortModel) => (item?.firstName || '') + ' ' + (item?.lastName || '')
			},
			{ key: 'email', label: 'Email', isSort: false },
			{
				key: 'services.name', label: 'Visitor Type', isSort: false,render: (item: ContractortModel) => item.services?.map((item:ServiceModel) => item.name).join(',')	
			},
			{
				key: 'employees',
				label: 'Employees',
				isSort: false,
				render: (item: ContractortModel) => item?.employees?.length
			},
			{
				key: 'activeLocations',
				label: 'Active Locations',
				isSort: true,
				render: (item: ContractortModel) => item?.assignedLocations?.length
			},
			// {
			// 	key: 'status',
			// 	label: 'Status',
			// 	isSort: true,
			// },
		],
		filters: [
			{
				name: 'Status',
				key: 'status',
				items: [
					{ label: 'Active', value: 'ACTIVE' },
					{ label: 'Inactive', value: 'INACTIVE' },
					{ label: 'Invited', value: 'DRAFT' }
				],
				multiple: false
			},
		]
	};
}

export class ContractorProfileState extends BaseStateModel {
	public loading: boolean = false;
	public edit: boolean = false;
	public tab: number = 0;
	public showAll: boolean = true;
	public activity?: Array<ActivityListModel> = [];
	public contractorNotes: NotesModel = {
		notes: '',
	}
	public contractor: ContractortModel = {
		companyName: '',
		trade: '',
		contactName: '',
		email: '',
		phone: '',
		locations: [],
		invite: '',
		company: {},
		service: [],
		employees: [],
		insurences: [],
		licenses: []
	};

	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'locations',
		headers: [
			{ key: 'name', label: 'Building Name/Referance', isSort: true },
			{ key: 'address', label: 'Address', isSort: true, style: { minWidth: '200px' } },
			{ key: 'company', label: 'Company name', isSort: true },
			{ key: 'contactName', label: 'Contact Name', isSort: true },
			{ key: 'contactEmail', label: 'Contact Email', isSort: true },
			{ key: 'contactPhone', label: 'Contact Phone', isSort: true },
		]
	};

	public empDataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'employees',
		headers: [
			{ key: 'firstName', label: 'First Name', isSort: true },
			{ key: 'lastName', label: 'Last Name', isSort: true },
			{ key: 'email', label: 'Email', isSort: true },
			{ key: 'phone', label: 'Phone', isSort: true },
		]
	};
}