import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, ServiceHistoryModel } from '../Redux/Models';

export default class ServicesHistoryService extends BaseService {
	public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<ServiceHistoryModel>> {
		let url: string = '/service-history';
		if (options?.root) {
			url = options.root + url;
		}
		ServicesHistoryService.initCancelToken();
		if (options?.source) {
			ServicesHistoryService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: ServicesHistoryService.source?.token })
				.then((res) => {
					let data = new ResponseList<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getDetailsById(id: number, params: Object = {}): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/service-history/' + id, { params, cancelToken: ServicesHistoryService.source?.token })
				.then((res) => {
					let data = new Response<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static create(params: Object = {}): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/service-history', params, { cancelToken: ServicesHistoryService.source?.token })
				.then((res) => {
					let data = new Response<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/service-history/' + id, { ...params, cancelToken: ServicesHistoryService.source?.token })
				.then((res) => {
					let data = new Response<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/service-history/' + id, { cancelToken: ServicesHistoryService.source?.token })
				.then((res) => {
					let data = new Response<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static toggleStatus(id: number): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/service-history/' + id + '/status', { cancelToken: ServicesHistoryService.source?.token })
				.then((res) => {
					let data = new Response<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getServices(params = {}): Promise<Response<ServiceHistoryModel[]>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/service-history', {
				params: { status: 'ACTIVE', ...params },
				cancelToken: ServicesHistoryService.source?.token
			})
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static changeInductionStatus(taskId: number, params = {}): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put(`/service-history/${taskId}/induction`, params, {
				cancelToken: ServicesHistoryService.source?.token
			})
				.then((res) => {
					let data = new Response<ServiceHistoryModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				})
		})
	}

	public static getFile(params: Object = {}): Promise<Response<ServiceHistoryModel>> {
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/download', params, { responseType: 'blob' }).then((res) => {
				let data = new Response<ServiceHistoryModel>(res);
				return resolve(data);
			}).catch((err) => {
				return reject(err);
			})
		})
	}

	public static taskStatusChange(id:number,params:Object={}){
		ServicesHistoryService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch(`/service-history/${id}/status`,params,{
				cancelToken: ServicesHistoryService.source?.token
			}).then((res) => {
				let data = new Response<ServiceHistoryModel>(res);
				return resolve(data);
			}).catch((err) => {
				return reject(err);
			})
		})
	}
}
