import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import './App.css';
import { PrimaryTheme } from './Theme';
import { ThemeProvider } from '@mui/material';
import { RouteInterface, routes } from './Routes';
import { AuthUserModel, Props, StateModel } from 'Redux/Models';
import { connect } from 'react-redux';
import { Filter, Map } from 'Helpers/Array';
import { Component } from 'react';
class App extends Component<Props> {

	public render() {
		const { isAuthenticated } = this.props;
		return (
			<ThemeProvider theme={PrimaryTheme}>
				<div className="App">
					<Router>
						<Switch>
							{isAuthenticated &&
								Map(
									Filter(routes, (item) => item.auth),
									(route: RouteInterface, i) => {
										return <Route key={i} exact={route.exact} path={route.path} render={(rProps: any) => <route.component {...this.props} {...rProps} childrens={route.childrens} />} />;
									}
								)}
							{!isAuthenticated &&
								Map(
									Filter(routes, (item) => !item.auth),
									(route: RouteInterface, i) => {
										return <Route key={i} exact={route.exact} path={route.path} render={(rProps: any) => <route.component {...this.props} {...rProps} childrens={route.childrens} />} />;
									}
								)}
						</Switch>
					</Router>
				</div>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state: StateModel) => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
		user: new AuthUserModel(state.Auth.user),
		deviceToken: state.Auth.deviceToken,
		isExpired: state.Auth.isExpired,
	};
};

export default connect(mapStateToProps)(App);
