import { MuiLink, MuiTooltip } from "Components/MUI";
import { getFullPath } from "Helpers/String";
import { Component, ReactNode } from "react";
import { States } from "Redux/Models";
import { ServicesHistoryService } from "Services";

export default class DownloadLink extends Component<{ link: string, children: any, type?: string, title?: string }> {
    constructor(props: any) {
        super(props);
        this.state = {
            url: props.link,
            fileName: '',
            type: props.type || ''
        }
    }

    public download = () => {
        const { type }: States = this.state;
        if (this.props.link && type !== 'CSV') {
            const params = { file: this.props.link }
            ServicesHistoryService.getFile(params)
                .then(({ data: resp, headers }: any) => {
                    const url = window.URL.createObjectURL(new Blob([resp] as any,));
                    this.setState((prevState: any) => {
                        prevState.url = url;
                        prevState.fileName = headers.name;
                        return prevState;
                    }, () => this.downloadDocument());
                })
        } else if (type === 'CSV' && this.props.link) {
            this.downloadDocument();
        }
    }

    public downloadDocument = (): void => {
        const { url, fileName, type }: States = this.state;
        const link = window.document.createElement('a');
        link.href = type === 'CSV' ? getFullPath(this.props.link)! : url;
        link.setAttribute('download', fileName)
        window.document.body.appendChild(link);
        link.click();
        link.remove()
    }

    public render(): ReactNode {
        const { title } = this.props
        return (
            <MuiTooltip title={title || "Click to download"}>
                <MuiLink onClick={this.download} sx={{
                    cursor: 'pointer',
                    ":hover": {
                        color: '#8DCBDA'
                    }
                }}> {this.props.children} </MuiLink>
            </MuiTooltip>
        )
    }
}