import { GetDateTime } from 'Helpers/String';
import { ActivityListModel, BaseStateModel, DataTableModel, GENDER, RoleModel, UserModel } from '../Models';

export class UserProfileState extends BaseStateModel {
	public termsAgree:boolean=false;
	public loading: boolean = false;
	public statusColor: string = '';
	public tab: number = 0;
	public formErr?: { [key: string]: any } = {}
	public user: UserModel = {
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		gender: GENDER.MALE,
		image: '',
		role: '',
		companyName: '',
	};
	public changePassword: UserModel & { currentPassword: string } = {
		currentPassword: '',
		password: '',
		confirmPassword: ''
	};
}

export class UserListState extends BaseStateModel {
	public modalOpen: boolean = false;
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'users',
		headers: [
			{ key: 'firstName', label: 'First Name', isSort: true },
			{ key: 'lastName', label: 'Last Name', isSort: true },
			{ key: 'email', label: 'Email', isSort: true },
			{
				key: 'updatedAt',
				label: 'Last Updated',
				isSort: true,
				render: (item: UserModel) => GetDateTime(item.updatedAt)
			}
		],
		filters: []
	};
}

export class AddUserState extends BaseStateModel {
	public loading: boolean = false;
	public roles: Array<RoleModel> = [];
	public user: UserModel = {
		firstName: '',
		lastName: '',
		phone: '',
		role: '',
		email: '',
		gender: GENDER.MALE,
		image: '',
		roles: []
	};
}

export class UserDetailState extends BaseStateModel {
	public loading: boolean = false;
	public tab: number = 0;
	public user: UserModel = new UserModel();
	public activity?: Array<ActivityListModel> = [];
}
