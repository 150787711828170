import { GetDateTime, GetHourDifference } from 'Helpers/String';
import { BaseStateModel, DataTableModel, LocationModel, LocationSearchModel, UploadModel } from 'Redux/Models';

export class LocationListState extends BaseStateModel {
	public selectedLocation?: LocationModel | null = null;
	public type: 'QR' | 'GUIDELINE' = 'QR';
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'locations?with=owner',
		headers: [
			{ key: 'name', label: 'Building Name/Referance', isSort: true },
		],
		filters: [
			{
				name: 'Subscription',
				key: 'subscription',
				items: [
					{ label: 'Active', value: 'ACTIVE' },
					{ label: 'Expired', value: 'EXPIRED' },

				],
				multiple: false
			},
		]
	};
}

export class LocationAddState extends BaseStateModel {
	public loading: boolean = false;
	public open: boolean = false;
	public locationList: Array<LocationSearchModel> = [];
	public location: LocationModel = {
		name: '',
		selectedAddress: {},
		address: '',
		contactEmail: '',
		contactName: '',
		contactPhone: '',
		ownerEmail: '',
		company: '',
		ownerName: '',
		ownerPhone: '',
		logo: ''
	};
}

export class LocationQRDialogState extends BaseStateModel {
	public loading: boolean = false;
	public model: { email: string } = {
		email: ''
	};
}

export class DuidelineDialogState extends BaseStateModel {
	public loading: boolean = false;
	public guidelines: Array<UploadModel> = [];
	public model: LocationModel = {};
}

export class LocationDetailsState extends BaseStateModel {
	public loading: boolean = false;
	public editOpen: boolean = false;
	public tab: number = 0;
	public location: LocationModel = {
		name: '',
		address: '',
		contactEmail: '',
		contactName: '',
		contactPhone: '',
		ownerEmail: '',
		company: '',
		ownerName: '',
		ownerPhone: '',
		createdAt: '',
		uploads: [],
		newLogo: ''
	};
	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'users',
		headers: [
			{ key: 'assignedUser.name', label: 'Name', isSort: false, render: (item: any) => item.name },
			{ key: 'assignedUser.type', label: 'Type', isSort: false, render: (item: any) => item.type },
			{ key: 'user.email', label: 'Email', isSort: false, render: (item: any) => item.email },
		],
		filters: [
			// { key: 'serviceId', name: 'Service Type', items: [], multiple: true },
		]
	};
}
