import { SubscriptionModel, BaseStateModel, PlanModel } from 'Redux/Models';

export class SubscriptionState extends BaseStateModel {
	public loading: boolean = false;
	public plans: Array<PlanModel> = [];
	public activeSubscription: SubscriptionModel = {
		planId: '',
		planName: ''
	};
	public site: SubscriptionModel = {
		planId: '',
		planName: ''
	};
}

export class MySubscriptionState extends BaseStateModel {
	public activeSubscription: SubscriptionModel = {
		planId: '',
		planName: '',
		invoices: []
	};
}

export class UpgradeSubscriptionState extends BaseStateModel {
	public loading: boolean = false;
	public tab: number = 0;
	public plans: Array<PlanModel> = [];
	public yearlyPlans: Array<PlanModel> = [];
	public monthlyPlans: Array<PlanModel> = [];
	public activeSubscription: SubscriptionModel = {
		planId: '',
		planName: ''
	};
	public site: SubscriptionModel = {
		planId: '',
		planName: ''
	};
	public clickedPlan: Object = {
		users: '',
		locations: ''
	}
}


export class PriceState extends BaseStateModel {
	public loading: boolean = false;
	public plans: Array<PlanModel> = [];
	public yearlyPlans: Array<PlanModel> = [];
	public monthlyPlans: Array<PlanModel> = [];
	public tab: number = 0;
}

export class ClientSubscriptionState extends BaseStateModel {
	public loading: boolean = false;
	public plan?: PlanModel;
}
